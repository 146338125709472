import styled from "styled-components";
import { mixinWrapper, mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  ${mixinWrapper}
  padding: 72px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
export const Title = styled.h1`
  font-family: "CircularMedium";
  font-size: 3.8rem;
  font-weight: 500;
  text-align: center;
  color: ${colors.black};
  max-width: 718px;
  margin: auto;

  & > strong {
    font-weight: 500;
    color: ${(props) => (props.color === "red" ? colors.red1 : colors.blue3)};
  }

  ${mixinRespondTo.md`
    font-size: 3.2rem;
  `}
`;
export const Body = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 38px;
  margin-top: 64px;
  p {
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    text-align: left;
    line-height: 1.5;
    color: ${colors.gray3};
    margin-bottom: 24px;
  }

  ${mixinRespondTo.md`
   margin-top: 44px;
     grid-template-columns: repeat(1, 1fr);
  `}
`;

export const Text = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  text-align: left;
  line-height: 1.5;
  color: ${colors.gray3};
`;
