function setOptionForSelect(options, fieldName, fieldValue, formRef) {
  const selectedIndex = options.findIndex((i) => {
    const value = fieldValue !== null ? fieldValue : "";
    return `${i.value}` === `${value}`;
  });

  if (selectedIndex !== -1) {
    formRef.current.setFieldValue(fieldName, options[selectedIndex]);
  }
}

export const useSetFieldValues = (formRef, formData) => {
  return {
    setValuesForRadioInputs: (fieldNames = []) => {
      if (!formData) return null;
      fieldNames.forEach((fieldName) => {
        formRef.current.setFieldValue(fieldName, `${formData[fieldName]}`);
      });
    },
    setValuesForSelectInputs: (mappingOptions = {}) => {
      if (!formData) return null;

      Object.keys(mappingOptions).forEach((fieldName) => {
        const fieldValue = formData[fieldName];

        const isArray = Array.isArray(mappingOptions[fieldName]);
        const isObject = mappingOptions[fieldName] instanceof Object;

        if (isObject && !isArray) {
          const deepMappingOptions = mappingOptions[fieldName];

          Object.keys(deepMappingOptions).forEach((deepFieldName) => {
            const deepFieldValue = formData[fieldName][deepFieldName];
            setOptionForSelect(
              deepMappingOptions[deepFieldName],
              `${fieldName}.${deepFieldName}`,
              deepFieldValue,
              formRef
            );
          });
        } else {
          setOptionForSelect(
            mappingOptions[fieldName],
            fieldName,
            fieldValue,
            formRef
          );
        }
      });
    },
  };
};
