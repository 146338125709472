import styled, { css } from "styled-components";
import { colors } from "@/styles/variables";

export const MainContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  padding: 32px;
  border-radius: 8px;

  div.header {
    font-family: "CircularMedium";
    font-size: 18px;
    margin-bottom: 28px;
  }

  div.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0;
    div.label {
      font-size: 15px;
    }
    div.value {
      font-size: 22px;
      font-family: "Roboto", sans-serif;
      font-weight: 300;
    }
  }
`;

// courses and agents

export const CoursesAndAgentsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 42px;
  margin-bottom: 42px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const CoursesCard = styled(Card)`
  background: ${colors.blue3};
  color: #fff;
`;

export const AgentsCard = styled(Card)`
  background: ${colors.blue3};
  color: #fff;
`;

// groups

export const GroupsCard = styled(Card)`
  background: ${colors.blue5};
  color: #fff;
`;

// groups grid

export const GroupsContainer = styled.div`
  border-top: 1px solid #e5e5e5;
  margin-top: 48px;
  padding-top: 42px;
`;

const groupsGridBaseCss = css`
  display: grid;
  grid-template-columns: auto repeat(2, minmax(auto, 120px));
  gap: 0 32px;

  div:nth-child(2),
  div:nth-child(3) {
    text-align: right;
  }

  @media screen and (max-width: 768px) {
    gap: 0 16px;
  }
`;

export const GroupsGridHeader = styled.div`
  ${groupsGridBaseCss};
  margin-bottom: 20px;
  font-size: 16px;
`;

export const GroupsGridItem = styled.div`
  ${groupsGridBaseCss};
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 8px;
`;
