import React, { useRef, useEffect, useCallback } from "react";
import ReactSelect from "react-select";
import { useField } from "@unform/core";

import { colors } from "@/styles/variables";
import { Container } from "./styles";

const Select = ({ name, label, required, options, value, ...rest }) => {
  const selectRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const inputStyles = {
    control: (styles) => ({
      ...styles,
      border: error ? `solid 1px ${colors.red1}` : `solid 1px ${colors.gray4}`,
    }),
    input: (styles) => ({
      ...styles,
      flex: 1,
      borderRadius: "4px",
      fontSize: "1.7rem",
      padding: "15px",
      width: "auto",
    }),
  };

  const valueChangedCB = useCallback(() => {
    if (selectRef?.current) {
      selectRef.current.setState((state) => ({ ...state, value: value }));
    }
  }, [value, selectRef]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: "state.value",
      setValue: (ref, value) => {
        ref.setState((state) => ({ ...state, value }));
      },
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
    });
  }, [selectRef, fieldName, registerField, rest.isMulti]);

  useEffect(() => {
    valueChangedCB();
  }, [valueChangedCB]);

  return (
    <Container error={error}>
      {label && (
        <label>
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <ReactSelect
        name={fieldName}
        styles={inputStyles}
        defaultValue={defaultValue}
        ref={selectRef}
        options={options}
        {...rest}
      />
      {error && <span className="error">{error}</span>}
    </Container>
  );
};

export default Select;
