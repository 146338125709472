import styled from "styled-components";
import { mixinWrapper, mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";
export const Container = styled.div`
  ${mixinWrapper};
  margin-top: 96px;
  margin-bottom: 96px;
`;

export const Title = styled.h1`
  position: relative;
  font-family: "CircularMedium", sans-serif;
  font-size: 3.8rem;
  font-weight: 500;
  text-align: center;
  color: ${colors.black};

  &::after {
    content: "";
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 63px;
    height: 4px;
    background-color: ${colors.yellow2};
  }
`;

export const CardGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  grid-gap: 40px;
  margin-top: 44px;
  ${mixinRespondTo.md`
    display: none;
  `}
`;

export const CarouselGroup = styled.div`
  display: none;
  margin: 0 auto;
  max-width: 340px;
  ${mixinRespondTo.md`
    display: flex;
  `}
`;

export const Slide = styled.div`
  margin-bottom: 20px;
`;
