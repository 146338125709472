import React from "react";

import * as S from "./styles";

const Paginator = ({ pagination, onPageChange, neighbors = 2 }) => {
  function handleClick(page) {
    if (onPageChange) onPageChange(page);
  }

  const Buttons = () => {
    if (!pagination) return <></>;

    const { page: currentPage, lastPage: totalPages } = pagination;

    const page = parseInt(currentPage, 10);
    const pages = parseInt(totalPages, 10);

    const targetSize = neighbors * 2;
    const middle = Math.floor(targetSize / 2);
    const paginatorArray = [];

    if (pages > 1) {
      let inicio = page > middle ? page - middle : 1;
      inicio = inicio > pages - targetSize ? pages - targetSize : inicio;
      inicio = inicio < 1 ? 1 : inicio;
      let t = inicio + targetSize;
      t = pages < t ? pages : t;
      for (let i = inicio; i <= t; i += 1) paginatorArray.push(i);
    }

    return (
      <S.Container>
        {paginatorArray.map((index) => (
          <S.Button
            active={page === index}
            key={index}
            onClick={() => handleClick(index)}>
            {index}
          </S.Button>
        ))}
      </S.Container>
    );
  };
  return <Buttons />;
};
export default Paginator;
