import React from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { date, telefone } from "utils/formatters";
import {
  Container,
  Estado,
  Cidade,
  Title,
  Date,
  DateStart,
  DateEnd,
  Contato,
} from "./styles";

const state = {
  AC: { name: "Acre" },
  AL: { name: "Alagoas" },
  AP: { name: "Amapá" },
  AM: { name: "Amazonas" },
  BA: { name: "Bahia" },
  CE: { name: "Ceará" },
  ES: { name: "Espírito Santo" },
  GO: { name: "Goiás" },
  MA: { name: "Maranhão" },
  MT: { name: "Mato Grosso" },
  MS: { name: "Mato Grosso do Sul" },
  MG: { name: "Minas Gerais" },
  PA: { name: "Pará" },
  PB: { name: "Paraíba" },
  PR: { name: "Paraná" },
  PE: { name: "Pernambuco" },
  PI: { name: "Piauí" },
  RJ: { name: "Rio de Janeiro" },
  RN: { name: "Rio Grande do Norte" },
  RS: { name: "Rio Grande do Sul" },
  RO: { name: "Rondônia" },
  RR: { name: "Roraima" },
  SC: { name: "Santa Catarina" },
  SP: { name: "São Paulo" },
  SE: { name: "Sergipe" },
  TO: { name: "Tocantins" },
  DF: { name: "Distrito Federal" },
};

function Card({ course }) {
  const { city, name, date_start, date_end, phone } = course;

  return (
    <Container>
      <Estado>{state[city.uf].name}</Estado>
      <Cidade>{city.name}</Cidade>
      <Title>{name}</Title>
      <Date>
        <DateStart>
          <AiOutlineCalendar size={18} color="#e54141" />
          Início: {date(date_start, { format: "dd/MM/yyyy" })}
        </DateStart>
        {date_end && (
          <DateEnd>
            <AiOutlineCalendar size={18} color="#e54141" />
            Fim: {date(date_end, { format: "dd/MM/yyyy" })}
          </DateEnd>
        )}
      </Date>
      {phone && <Contato>Mais informações: {telefone(phone)}</Contato>}
    </Container>
  );
}

export default Card;
