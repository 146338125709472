import React from "react";
import { useGoTop } from "@/hooks/useGoTop";
import Banner from "./Banner";
import SectionActions from "./SectionActions";
import Jumbotron from "@/components/Jumbotron";
import CookiesWarning from "@/components/CookiesWarning";
import NewsSection from "@/components/NewsSection";
import { Button } from "@/styles/components/Button";
import { Container } from "./styles";
import BannerHomeImg from "@/assets/images/banner/BannerHome.jpg";
import Jumbotron1Img from "@/assets/images/jumbotron/Jumbotron1.jpg";
import Jumbotron2Img from "@/assets/images/jumbotron/Jumbotron2.jpg";
import { ReactComponent as PrayIcon } from "@/assets/icons/pray.svg";
import { ReactComponent as MeetingIcon } from "@/assets/icons/meeting.svg";
import { ReactComponent as FormIcon } from "@/assets/icons/form.svg";
import { ReactComponent as ChurchIcon } from "@/assets/icons/church.svg";

function Home() {
  const bannerItens = [
    {
      icon: <PrayIcon />,
      title: "Os 12 Passos",
      link: "/12-passos",
      description:
        "Saiba quais são os passos e conheça a oração da Sobriedade.",
    },
    {
      icon: <MeetingIcon />,
      title: "Grupos de Autoajuda",
      link: "/grupos/como-funciona",
      description:
        "Reuniões semanais, implantadas nas Paróquias ou comunidades.",
    },
    {
      icon: <FormIcon />,
      title: "Cursos de Formação",
      link: "/sobre/curso-formacao",
      description: "Confira a agenda nacional de cursos ministrados pela CNBB",
    },
    {
      icon: <ChurchIcon />,
      title: "Comunidades Terapêuticas Parceiras",
      link: "/comunidades/nossas-comunidades",
      description:
        "Conheça as Comunidades parceiras com o Programa de Vida Nova da Pastora da Sobriedade",
    },
  ];

  useGoTop();

  return (
    <Container>
      <CookiesWarning active />
      <Banner image={BannerHomeImg} itens={bannerItens} />
      <SectionActions />

      <Jumbotron image={Jumbotron1Img}>
        <h1>Encontre um grupo de autoajuda perto de você</h1>
        <p>
          Nossos grupos estão por todo o Brasil, nas comunidades e paróquias da
          Igreja. Realizamos reuniões semanais onde se propõe um Programa de
          Vida Nova, através dos 12 passos, baseados na reflexão e vivência de
          12 temas bíblicos de conversão!
        </p>
        <div>
          <Button to="/grupos/encontrar-grupo">Encontre um grupo</Button>
          <Button light to="/12-passos">
            Conheça os passos
          </Button>
        </div>
      </Jumbotron>

      <NewsSection title="Últimas notícias" />

      <Jumbotron image={Jumbotron2Img}>
        <h1>Curso de Formação e Capacitação da Pastoral da Sobriedade</h1>
        <p>
          Você que quer se dedicar à Pastoral pode fazer um dos nossos cursos
          ministrados pelos agentes multiplicadores da Pastoral da Sobriedade da
          sua região. E desenvolver-se em tópicos como a espiritualidade da
          Pastoral, a estrutura da Reunião de Autoajuda, características do
          agente, entre outros conteúdos importantes para a sua formação.
        </p>

        <Button to="/sobre/curso-formacao">Cursos de capacitação</Button>
      </Jumbotron>
    </Container>
  );
}

export default Home;
