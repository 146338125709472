import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import {
  Container,
  Wrapper,
  Bio,
  Logo,
  BioDescription,
  BioSocial,
  Itens,
  Title,
  List,
  ListWrap,
  ListTitle,
  ListItem,
  SubFooter,
  SubFooterLeft,
  SubFooterRight,
  AreaRestritaLink,
  FooterAllRights,
} from "./styles";

function Footer() {
  return (
    <Container>
      <Wrapper>
        <Bio>
          <Logo to="/">
            <LogoIcon />
            <h1>
              Pastoral da <strong>Sobriedade</strong>
            </h1>
          </Logo>

          <BioDescription>
            A Pastoral da Sobriedade é a ação concreta da Igreja na Prevenção e
            Recuperação da Dependência Química.Buscamos a integração entre todas
            as Pastorais, Movimentos, Comunidades Terapêuticas Parceiras e Casas
            de Recuperação.
          </BioDescription>

          <BioSocial>
            <a href="https://www.instagram.com/pastoraldasobriedadecnbb/">
              <FaInstagram />
            </a>
            <a href="https://www.facebook.com/pastoraldasobriedadeoficial">
              <FaFacebookF />
            </a>
            <a href="https://www.youtube.com/channel/UCm7Bvl1DW9g1f6JLMEVDfyQ">
              <FaYoutube />
            </a>
          </BioSocial>
        </Bio>

        <Itens>
          <List column>
            <Title to="/">Início</Title>
            <ListTitle>Pastoral</ListTitle>
            <ListWrap>
              <ListItem>
                <Link to="/sobre">Sobre a Pastoral</Link>
              </ListItem>
              <ListItem>
                <Link to="/sobre/curso-formacao">Cursos de Formação</Link>
              </ListItem>
              <ListItem>
                <Link to="/sobre/terco-sobriedade">Terço da Sobriedade</Link>
              </ListItem>
              <ListItem>
                <Link to="/sobre/arquivos-materiais">Arquivos e Materiais</Link>
              </ListItem>
              <ListItem>
                <Link to="/sobre/estatisticas">Estatísticas</Link>
              </ListItem>
            </ListWrap>
          </List>

          <List>
            <Title to="/12-passos">Os 12 Passos</Title>
            <ListTitle>Grupos de Ajuda</ListTitle>
            <ListItem>
              <Link to="/grupos/como-funciona">Como funciona</Link>
            </ListItem>
            <ListItem>
              <Link to="/grupos/encontrar-grupo">Encontre um Grupo</Link>
            </ListItem>
            <ListItem>
              <Link to="/grupos/calendario">Calendário de Reuniões</Link>
            </ListItem>
            <ListItem>
              <Link to="/grupos/como-implantar">Como implantar</Link>
            </ListItem>
          </List>

          <List>
            <ListTitle>Novidades</ListTitle>
            <ListItem>
              <Link to="/novidades/noticias">Notícias</Link>
            </ListItem>
            <ListItem>
              <Link to="/novidades/eventos">Eventos</Link>
            </ListItem>
            <ListTitle space="top">
              Comunidades Terapêuticas Parceiras
            </ListTitle>
            <ListItem>
              <Link to="/comunidades/nossas-comunidades">
                Conheça nossas Comunidades Parceiras
              </Link>
            </ListItem>
            <ListItem>
              <Link to="/comunidades/cadastro">
                Cadastro de Pré-Filiação de Comunidade Terapêutica Parceira
              </Link>
            </ListItem>
            <AreaRestritaLink
              href="http://sobriedade.org.br/dashboard/"
              target="_blank">
              Área Restrita
            </AreaRestritaLink>
          </List>
        </Itens>
      </Wrapper>
      <SubFooter>
        <SubFooterLeft>
          <dl>CNBB - Coordenação Nacional da Pastoral da Sobriedade</dl>
          <dl>
            Sede:Rua Fernando Ferrari, 128 Retiro , Volta Redonda – RJ CEP:
            27281-660
          </dl>
          <dl>Contato: (24) 30136218 | Celular: (24)99994-9253</dl>
          <dl>
            <a href="mailto:cnbb3@sobriedade.org.br">cnbb3@sobriedade.org.br</a>
          </dl>
        </SubFooterLeft>
        <SubFooterRight>
          <dl>© Direitos reservados</dl>
          <dl>Pastoral da Sobriedade.org</dl>
          <dl>Desenvolvido por Foco Comunicação</dl>
        </SubFooterRight>
      </SubFooter>
      <FooterAllRights>
        <a href="./docs/politica_privacidade.pdf">Politica de Privacidade</a>
      </FooterAllRights>
    </Container>
  );
}

export default Footer;
