import React from "react";
import { useGoTop } from "@/hooks/useGoTop";
import SectionProposal from "./SectionProposal";
import SectionFeatures from "./SectionFeatures";
import Banner from "@/components/Banner";
import Jumbotron from "@/components/Jumbotron";
import JumbotronCenter from "@/components/JumbotronCenter";
import { Button } from "@/styles/components/Button";
import { ReactComponent as CrossIcon } from "@/assets/icons/cross.svg";
import BannerWorks from "@/assets/images/banner/BannerWorks.jpg";
import Jumbotron1Img from "@/assets/images/jumbotron/Jumbotron1.jpg";
import Jumbotron4Img from "@/assets/images/jumbotron/Jumbotron4.jpg";
import { Container } from "./styles";

function Works() {
  useGoTop();
  return (
    <Container>
      <Banner
        image={BannerWorks}
        title="Como Funciona"
        description="Reuniões semanais, implantadas nas Paróquias ou comunidades,
onde se propõe um Programa de Vida Nova que leva à conversão."
        descriptionWidth={496}
      />

      <SectionProposal />

      <JumbotronCenter
        image={Jumbotron4Img}
        titleWidth={656}
        descriptionWidth={548}>
        <CrossIcon />
        <h1>
          O Grupo de Autoajuda pretende levar o co-dependente e o dependente a
          fazerem a terapia do amor
        </h1>
        <p>
          Isto é, exercitarem o amor incondicional, pois quem ama conduz para a
          vida eterna, através do conhecimento de Jesus e de seu Projeto
          Libertação.
        </p>
      </JumbotronCenter>

      <SectionFeatures />

      <Jumbotron image={Jumbotron1Img}>
        <h1>Encontre um grupo de autoajuda perto de você</h1>
        <p>
          Nossos grupos estão por todo o Brasil, nas comunidades e paróquias da
          Igreja. Realizamos reuniões semanais onde se propõe um Programa de
          Vida Nova, através dos 12 passos, baseados na reflexão e vivência de
          12 temas bíblicos de conversão!
        </p>
        <div>
          <Button to="/grupos/encontrar-grupo">Encontre um grupo</Button>
          <Button light to="12-passos">
            Conheça os passos
          </Button>
        </div>
      </Jumbotron>
    </Container>
  );
}

export default Works;
