import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  MenuMobileNav,
  MenuMobileButton,
  SubMenuMobile,
  MenuIcon,
} from "./styles";

function MenuMobile({ menu }) {
  const [checked, setChecked] = useState(false);
  const [modal, setModal] = useState();

  function buildLinkComponent(item) {
    const { title, link, external = true } = item;

    if (external) {
      return (
        <a
          href={link}
          target={"_blank"}
          onClick={() => {
            setModal("");
            setChecked(false);
          }}>
          {title}
        </a>
      );
    }

    return (
      <Link
        to={link}
        onClick={() => {
          setModal("");
          setChecked(false);
        }}>
        {title}
      </Link>
    );
  }

  useEffect(() => {
    checked
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "initial");
  }, [checked]);

  return (
    <Container>
      <MenuMobileButton
        onClick={() => {
          setModal("");
          setChecked(!checked);
        }}>
        <MenuIcon checked={checked} />
      </MenuMobileButton>
      <MenuMobileNav checked={checked}>
        <ul>
          {menu.map((item, i) => (
            <li key={i}>
              {item.itens.length > 0 ? (
                <p onClick={() => setModal(item.title)}>{item.title}</p>
              ) : (
                buildLinkComponent(item)
              )}

              <SubMenuMobile open={modal === item.title}>
                {!!item.itens.length &&
                  item.itens.map((subitem, i) => (
                    <li key={i}>
                      <Link
                        to={subitem.link}
                        onClick={() => {
                          setModal("");
                          setChecked(false);
                        }}>
                        {subitem.title}
                      </Link>
                    </li>
                  ))}
              </SubMenuMobile>
            </li>
          ))}
        </ul>
      </MenuMobileNav>
    </Container>
  );
}

export default MenuMobile;
