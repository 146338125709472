import styled from "styled-components";
import { shade } from "polished";
import { Link } from "react-router-dom";
import { mixinWrapper, mixinRespondTo } from "@/styles/tools";
import { Button } from "@/styles/components/Button";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 853px;

  ${mixinRespondTo.md`
     height: 420px;
     background-position: center;
  `}

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${colors.black};
    opacity: 0.5;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 0.3)
    );
  }
`;

export const BannerWrapper = styled.div`
  ${mixinWrapper}
  z-index: 2;
  margin: 0 auto;
  padding-top: 50px;
  height: 70%;

  ${mixinRespondTo.md`
       height: 50%;
  `}
`;

export const BannerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & > h1 {
    max-width: 528px;
    font-family: "CircularMedium";
    font-size: 4rem;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-align: left;
    color: ${colors.white};
  }

  ${mixinRespondTo.md`
    align-items: center;

    & > h1 {
      text-align: center;
      font-size: 3rem;
    }
  `}
`;

export const BannerButton = styled(Button)`
  margin-top: 32px;
`;
export const BannerList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;

  ${mixinRespondTo.md`
    display: none;
  `}
`;
export const BannerListItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: ${colors.white};
  max-width: 230px;
  cursor: pointer;

  & > svg {
    fill: ${colors.gray10};
    transition: all 0.3s ease;
  }

  & > h1 {
    font-size: 1.8rem;
    font-weight: 500;
    margin: 24px 0 16px;
    transition: all 0.3s ease;
  }

  & > p {
    font-family: "Roboto", sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    color: ${colors.gray10};
    transition: all 0.3s ease;
  }

  &:not(:last-child) {
    margin-right: 62px;
  }

  &:hover {
    color: ${colors.red1};

    & > svg {
      fill: ${colors.red1};
    }

    & > p {
      color: ${(props) => shade(0.2, colors.red1)};
    }
  }
`;
