import { call, put } from "redux-saga/effects";
import { api, queryBuilder } from "services";
import { RequestErrorHandler } from "utils";

import { ListNewsActions } from "store/ducks/news";

export function* listNewsRequest(action) {
  try {
    const { query = {} } = action;
    const queryString = queryBuilder({ ...query, unblockedOnly: 1 });

    const { data: responseBody } = yield call(
      api.get,
      `open/news?${queryString}`
    );

    const { data, pagination } = responseBody;
    yield put(ListNewsActions.success(data, pagination));
  } catch (error) {
    const { errorMessage } = RequestErrorHandler(error);
    yield put(ListNewsActions.failure(errorMessage));
  }
}
