import React, { useCallback } from "react";

import DrugsChart from "./DrugsChart";
import * as S from "./styles";

const perc = (value) => {
  return `${value.toFixed(2)}%`;
};

const Result = ({ result }) => {
  const AgentsCardComponent = useCallback(() => {
    const { agentsParticipatingInCourses: quant } = result;

    return (
      <S.AgentsCard>
        <div className="header">
          Agentes capacitados pela Pastoral da Sobriedade
        </div>
        <div className="row">
          <div className="label">Quantidade:</div>
          <div className="value">{quant}</div>
        </div>
      </S.AgentsCard>
    );
  }, [result]);

  const CoursesCardComponent = useCallback(() => {
    const { totalCourses } = result.courses;

    return (
      <S.CoursesCard>
        <div className="header">
          Cursos oferecidos pela Pastoral da Sobriedade
        </div>
        <div className="row">
          <div className="label">Quantidade:</div>
          <div className="value">{totalCourses}</div>
        </div>
      </S.CoursesCard>
    );
  }, [result]);

  const GroupsCardComponent = useCallback(() => {
    const { participants: total, avgWeeklyParticipants: weekly } =
      result.groupReports;

    return (
      <S.GroupsCard>
        <div className="header">
          Atendimentos prestados pela Pastoral da Sobriedade
        </div>
        <div className="row">
          <div className="label">Total de atendimentos:</div>
          <div className="value">{total}</div>
        </div>
        <div className="row">
          <div className="label">Média por reunião:</div>
          <div className="value">{weekly}</div>
        </div>
      </S.GroupsCard>
    );
  }, [result]);

  const GroupsGridComponent = useCallback(() => {
    const {
      totalGroups,
      statesWithGroups,
      regionalsWithGroups,
      diocesesWithGroups,
      paroquiasWithGroups,
    } = result.groups;

    return (
      <S.GroupsContainer>
        <S.GroupsGridHeader>
          <div>Grupos de autoajuda</div>
          <div>Quantidade:</div>
          <div>Percentual:</div>
        </S.GroupsGridHeader>
        <S.GroupsGridItem>
          <div>Total de grupos</div>
          <div>{totalGroups}</div>
          <div>100%</div>
        </S.GroupsGridItem>
        <S.GroupsGridItem>
          <div>Estados com grupos</div>
          <div>{statesWithGroups.quant}</div>
          <div>{perc(statesWithGroups.percentage)}</div>
        </S.GroupsGridItem>
        <S.GroupsGridItem>
          <div>Regionais com grupos</div>
          <div>{regionalsWithGroups.quant}</div>
          <div>{perc(regionalsWithGroups.percentage)}</div>
        </S.GroupsGridItem>
        <S.GroupsGridItem>
          <div>Dioceses com grupos</div>
          <div>{diocesesWithGroups.quant}</div>
          <div>{perc(diocesesWithGroups.percentage)}</div>
        </S.GroupsGridItem>
        <S.GroupsGridItem>
          <div>Paróquias com grupos</div>
          <div>{paroquiasWithGroups.quant}</div>
          <div>{perc(paroquiasWithGroups.percentage)}</div>
        </S.GroupsGridItem>
      </S.GroupsContainer>
    );
  }, [result]);

  return (
    <S.MainContainer>
      <S.CoursesAndAgentsContainer>
        <AgentsCardComponent />
        <CoursesCardComponent />
      </S.CoursesAndAgentsContainer>
      <GroupsCardComponent />
      <DrugsChart groupReports={result.groupReports} />
      <GroupsGridComponent />
    </S.MainContainer>
  );
};

export default Result;
