import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { RequestErrorHandler } from "utils";

import { CreateFnctcActions } from "store/ducks/fnctcs";

export function* createFnctcRequest(action) {
  try {
    const { postData, onSuccess } = action;
    const { data } = yield call(api.post, `open/fnctc`, postData);

    notify("success", "FGAS cadastrada com sucesso");
    if (onSuccess) onSuccess(data.id);
    yield put(CreateFnctcActions.success());
  } catch (error) {
    const { errorMessage } = RequestErrorHandler(error);
    notify("error", errorMessage);
    yield put(CreateFnctcActions.failure(errorMessage));
  }
}
