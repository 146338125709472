import styled from "styled-components";
import { Form as FormUnform } from "@unform/web";
import { mixinWrapper, mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  background-color: ${colors.gray2};
  padding-bottom: 40px;
`;
export const Wrapper = styled.div`
  ${mixinWrapper}
  max-width: 720px;
`;
export const Title = styled.h1`
  font-family: "CircularMedium", sans-serif;
  font-size: 3.8rem;
  font-weight: 500;
  text-align: center;
  color: ${colors.black};
  padding: 72px 0 34px;
`;

export const Form = styled(FormUnform)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    width: 100%;
  }

  & > div:last-child {
    margin-left: 42px;
  }

  & > div > label {
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-size: 1.7rem;
    font-weight: 500;
    text-align: left;
    color: ${colors.black};
    margin-bottom: 8px;
  }

  ${mixinRespondTo.md`
    flex-direction: column;
    
    & > div:last-child {
      margin-left: 0;
    }
  `}
`;

export const Subtitle = styled.h1`
  font-family: "CircularMedium";
  font-size: 2.8rem;
  font-weight: 500;
  text-align: left;
  color: ${colors.black};
  margin: 49px 0 17px;
`;

export const Result = styled.div``;

export const Description = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  text-align: left;
  color: ${colors.gray3};
  margin-bottom: 34px;
`;

export const List = styled.div``;
