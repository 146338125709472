import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ListCalendarsActions } from "store/ducks/calendar";

import * as S from "./styles";

export const CalendarDownloader = () => {
  const dispatch = useDispatch();

  const [buttonState, setButtonState] = useState({
    name: null,
    href: null,
  });

  const { data: calendars = [], loading } = useSelector(
    (state) => state.listCalendars
  );

  const fetchCalendars = useCallback(() => {
    dispatch(ListCalendarsActions.request());
  }, [dispatch]);

  const onCalendarsLoad = useCallback(() => {
    if (!calendars.length) return;
    const { name, path } = calendars[0];
    setButtonState({ name, href: path });
  }, [calendars, setButtonState]);

  useEffect(() => {
    fetchCalendars();
  }, [fetchCalendars]);

  useEffect(() => {
    onCalendarsLoad();
  }, [onCalendarsLoad]);

  useEffect(() => {
    return () => {
      dispatch(ListCalendarsActions.reset());
    };
  }, [dispatch]);

  return (
    <S.MainContainer>
      {buttonState.href && (
        <S.Button
          href={buttonState.href}
          target="_blank"
          download={buttonState.name}>
          Fazer download do Calendário dos 12 Passos
        </S.Button>
      )}
    </S.MainContainer>
  );
};
