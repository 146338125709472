import React from "react";

import { Container, Title, List, ListItem } from "./styles";

function SectionStart() {
  return (
    <Container>
      <Title>Início</Title>
      <List>
        <ListItem>Sinal da Cruz – Deus Trino</ListItem>
        <ListItem>Oferecimento</ListItem>
        <ListItem>Oração: Creio</ListItem>
        <ListItem>Oração: Pai Nosso</ListItem>
        <ListItem>Oração: 3 Ave Marias</ListItem>
      </List>
    </Container>
  );
}

export default SectionStart;
