import React from "react";
import { useHistory } from "react-router-dom";
import { animateScroll } from "react-scroll";

import { getExcerpt } from "utils";

import {
  Container,
  Card,
  CardBody,
  CardDesc,
  CardImg,
  CardTitle,
} from "./styles";

function NewsCard({ news }) {
  // definir também uma imagem default
  const imageSrc = news.imagePath;
  const history = useHistory();

  function handleGoTo() {
    animateScroll.scrollToTop();
    history.push(`/novidades/noticias/${news.id}`);
  }

  return (
    <Container onClick={handleGoTo}>
      <Card>
        <CardImg src={imageSrc} />
        <CardBody>
          <CardTitle>{news.title}</CardTitle>
          <CardDesc>{getExcerpt(news.post, 90)}</CardDesc>
        </CardBody>
      </Card>
    </Container>
  );
}

export default NewsCard;
