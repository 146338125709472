import styled from "styled-components";
import { colors } from "@/styles/variables";
export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 31px 0;
  border-top: 1px solid ${colors.gray3};

  &:first-child {
    border-top: 4px solid ${colors.blue3};
  }

  & > svg {
    width: 31.5px;
    height: 41.4px;
    margin-right: 21px;
    color: ${colors.black};
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const Title = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: 1.7rem;
  font-weight: 500;
  text-align: left;
  max-width: 271px;
  width: 100%;
`;

export const Description = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  text-align: left;
  color: ${colors.gray3};
  margin: 12px 0 16px;
`;

export const Link = styled.a`
  opacity: 0.9;
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: left;
  color: ${colors.blue3};
  & > svg {
    margin-right: 8px;
  }
`;
