import { combineReducers } from "redux";

import { listCalendars } from "./calendar";
import { cep } from "./cep";
import { selectCities } from "./cities";
import { course, listCourses } from "./courses";
import { selectDioceses } from "./dioceses";
import { event, listEvents } from "./events";
import { createFnctc, listFnctcs } from "./fnctcs";
import { listGroups } from "./groups";
import { selectParoquias } from "./paroquias";
import { news, listNews } from "./news";
import { selectRegionals } from "./regionals";
import { statistics, legacyStatistics } from "./statistics";

const reducers = combineReducers({
  listCalendars,
  cep,
  selectCities,
  course,
  listCourses,
  selectDioceses,
  event,
  listEvents,
  createFnctc,
  listFnctcs,
  listGroups,
  selectParoquias,
  news,
  listNews,
  selectRegionals,
  statistics,
  legacyStatistics,
});

export default reducers;
