import React from "react";

import { Container, Wrapper } from "./styles";

function Jumbotron({ image, children }) {
  return (
    <Container src={image}>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
}

export default Jumbotron;
