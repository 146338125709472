import styled from "styled-components";
import { mixinWrapper } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  background-color: ${colors.blue8};
  padding: 88px 0;
  .swiper-pagination-bullet {
    background-color: ${colors.blue6};
  }
  .swiper-pagination-bullet-active {
    background-color: ${colors.white};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mixinWrapper};
`;

export const Title = styled.h1`
  font-family: "CircularMedium", sans-serif;
  font-size: 3.8rem;
  font-weight: 500;
  text-align: center;
  color: ${colors.white};
  margin: 21px 0 32px;
`;
export const Description = styled.p`
  opacity: 0.9;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  text-align: center;
  color: ${colors.white};
`;

export const NoCursesMessage = styled.h4`
  color: ${colors.white};
  margin-top: 24px;
`;

export const GridCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 48px;
  margin-top: 68px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 760px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
