import { call, put } from "redux-saga/effects";
import { api, queryBuilder } from "services";
import { RequestErrorHandler } from "utils";

import { ListGroupsActions } from "store/ducks/groups";

export function* listGroupsRequest(action) {
  try {
    const { query = {} } = action;
    const queryString = queryBuilder(query);

    const { data: responseBody } = yield call(
      api.get,
      `open/group?${queryString}`
    );

    const { data, pagination } = responseBody;
    yield put(ListGroupsActions.success(data, pagination));
  } catch (error) {
    const { errorMessage } = RequestErrorHandler(error);
    yield put(ListGroupsActions.failure(errorMessage));
  }
}
