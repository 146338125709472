import React from "react";

import { Container, Spinner } from "./styles";

function Loading({ light = false }) {
  return (
    <Container>
      <Spinner light={light} />
    </Container>
  );
}

export default Loading;
