import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import "intl";
import "intl/locale-data/jsonp/pt-BR";

export const date = (dateString, options = { format: "dd/MM/yyyy" }) => {
  return format(new Date(dateString), options.format, {
    locale: ptBR,
  });
};

export const dayOfWeek = (dateString, options = { format: "EEEE" }) => {
  return format(new Date(dateString), options.format, {
    locale: ptBR,
  });
};
