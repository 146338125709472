import { useEffect, useCallback } from "react";
import { animateScroll as scroll } from "react-scroll";

export function useGoTop() {
  const goTop = useCallback(() => {
    scroll.scrollToTop();
  }, []);

  useEffect(() => {
    goTop();
  }, [goTop]);
}
