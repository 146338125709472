import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { useValidation, useSetFieldValues } from "hooks";
import { useGoTop } from "@/hooks/useGoTop";
import { simNaoOptions, statesOptions } from "utils";
import { CepActions } from "store/ducks/cep";
import { SelectCitiesActions } from "store/ducks/cities";
import { CreateFnctcActions } from "store/ducks/fnctcs";

import Input from "@/components/Form/Input";
import MaskedInput from "@/components/Form/InputMask";
import Select from "@/components/Form/Select";
import Textarea from "@/components/Form/TextArea";

import Banner from "@/components/Banner";
import BannerRegisterImg from "@/assets/images/banner/BannerCurso.jpg";
import * as S from "./styles";

import { Row } from "@/styles/components/Row";
import Loading from "@/components/Loading";

function Register() {
  const formRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.createFnctc);
  const { data: citiesOptions } = useSelector((state) => state.selectCities);
  const { data: addressData, loading: addressLoading } = useSelector(
    (state) => state.cep
  );
  const [canIAutocompleteAddress, setCanIAutocompleteAddress] = useState(false);
  const [formData, setFormData] = useState({
    zipcode: null,
    uf: null,
  });

  const { handleFormErrors } = useValidation();
  const { setValuesForSelectInputs } = useSetFieldValues(formRef, formData);

  const handleCepChange = useCallback(
    (event) => {
      const { value } = event.target;
      if (formData?.zipcode !== value && value.length === 9) {
        setFormData((state) => ({ ...state, zipcode: value }));
        setCanIAutocompleteAddress(true);
      }
    },
    [setFormData, setCanIAutocompleteAddress, formData]
  );

  const handleUfChange = useCallback(
    ({ value }) => {
      setFormData((state) => ({ ...state, uf: value }));
    },
    [setFormData]
  );

  const onCreateSuccess = useCallback(() => {
    history.push("/comunidades/cadastro/completo");
  }, [history]);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        cnpj: Yup.string().required("Informe o CNPJ"),
        razao_social: Yup.string().required("Informe a razão social"),
        nome_fantasia: Yup.string().required("Informe o nome fantasia"),
        foundation_date: Yup.date()
          .typeError("Data inválida")
          .required("Informe a data de fundação"),
        phone: Yup.string().required("Informe o telefone"),
        email: Yup.string()
          .email("E-mail inválido")
          .required("Informe o e-mail"),
        website: Yup.string()
          .url("URL inválida")
          .required("Informe a URL do site"),
        target_audience: Yup.string().required(
          "Informe a público a ser atendido"
        ),
        range_age: Yup.string().required("Informe a faixa etária alvo"),
        free_vacancy_qty: Yup.number().typeError("Informe a quandidade"),
        total_vacancy_qty: Yup.number().typeError("Informe a quandidade"),
        keep_vacancy_qty: Yup.number().typeError("Informe a quandidade"),
        family_payment: Yup.number().typeError("Selecione uma opção"),
        donation: Yup.number().typeError("Selecione uma opção"),
        covenants: Yup.number().typeError("Selecione uma opção"),
        sanitary_document: Yup.string().optional(),
        cnas: Yup.number().typeError("Selecione uma opção"),
        conen_comad: Yup.number().typeError("Selecione uma opção"),
        another_register: Yup.string().optional(),
        federal_law: Yup.string().optional(),
        state_law: Yup.string().optional(),
        municipal_law: Yup.string().optional(),
        expert_name: Yup.string().optional(),
        expert_graduation: Yup.string().optional(),
        know_sobriedade: Yup.number().typeError("Selecione uma opção"),
        meeting: Yup.number().typeError("Selecione uma opção"),
        meeting_frequency: Yup.string().optional(),
        methodology: Yup.string().optional(),
        manager_name: Yup.string().optional(),
        manager_role: Yup.string().optional(),
        manager_birth_date: Yup.string().optional(),
        manager_cpf: Yup.string().optional(),
        manager_rg: Yup.string().optional(),
        manager_phone: Yup.string().optional(),
        manager_email: Yup.string().optional().email("E-mail inválido"),
        manager_need: Yup.string().optional(),
        zipcode: Yup.string().optional(),
        uf: Yup.string().required("Selecione o estado"),
        city_id: Yup.string().required("Selecione a cidade"),
        address_street: Yup.string().optional(),
        address_number: Yup.string().optional(),
        address_neighborhood: Yup.string().optional(),
      });
      await schema.validate(data, { abortEarly: false });
      dispatch(CreateFnctcActions.request(data, onCreateSuccess));
    } catch (error) {
      handleFormErrors(error, formRef);
    }
  }

  useGoTop();

  const fetchAddressViaZipcodeCB = useCallback(() => {
    if (!formData.zipcode || !canIAutocompleteAddress) return null;
    dispatch(CepActions.request(formData.zipcode));
  }, [dispatch, canIAutocompleteAddress, formData.zipcode]);

  const addressDataHasChangeCB = useCallback(() => {
    if (!addressData) return null;

    const { uf, city_id, address_street, address_neighborhood } = addressData;

    formRef.current.setFieldValue("address_street", address_street || null);
    formRef.current.setFieldValue(
      "address_neighborhood",
      address_neighborhood || null
    );

    setFormData((state) => ({
      ...state,
      ...{ uf, city_id },
    }));
  }, [addressData, setFormData, formRef]);

  const fetchCitiesCB = useCallback(() => {
    if (!formData.uf) return null;
    dispatch(SelectCitiesActions.request({ uf: formData.uf }));
  }, [dispatch, formData.uf]);

  useEffect(() => {
    fetchAddressViaZipcodeCB();
  }, [fetchAddressViaZipcodeCB]);

  useEffect(() => {
    addressDataHasChangeCB();
  }, [addressDataHasChangeCB]);

  useEffect(() => {
    fetchCitiesCB();
  }, [fetchCitiesCB]);

  useEffect(() => {
    setValuesForSelectInputs({
      uf: statesOptions,
      city_id: citiesOptions,
    });
  }, [setValuesForSelectInputs, citiesOptions]);

  useEffect(() => {
    return () => {
      dispatch(CepActions.reset());
      dispatch(CreateFnctcActions.reset());
      dispatch(SelectCitiesActions.reset());
    };
  }, [dispatch]);

  return (
    <S.Container>
      <Banner
        image={BannerRegisterImg}
        title="Filiar Comunidade"
        description="Cadastro de Pré Filiação da Comunidade Terapêutica Parceira FGAS - Federação dos Grupos de Autoajuda da Sobriedade"
      />

      <S.Wrapper>
        <S.Title>Faça o cadastro de Pré-Filiação</S.Title>
        <S.SubTitle>
          Preencha os campos abaixo para pré-filiar sua Comunidade à Sobriedade.
          Ao enviar seu cadastro nós avaliaremos sua inscrição e entraremos em
          contato para dar continuidade à filiação.
        </S.SubTitle>
        <S.FormWrapper>
          <Form ref={formRef} onSubmit={handleSubmit} initialData={formData}>
            <Row>
              <Input name="razao_social" label="Razão Social" required />
              <Input name="nome_fantasia" label="Nome Fantasia" required />
            </Row>
            <Row>
              <MaskedInput type="cnpj" name="cnpj" label="CNPJ:" required />
              <Input
                type="date"
                name="foundation_date"
                label="Data de fundação:"
                required
              />
            </Row>
            <Row>
              <MaskedInput
                type="telefone"
                name="phone"
                label="Telefone:"
                required
              />
              <Input name="email" label="E-mail:" required />
            </Row>
            <Row>
              <Input name="website" label="Site:" />
            </Row>
            <Row>
              <Input name="target_audience" label="Público atendido:" />
              <Input name="range_age" label="Faixa etária:" />
            </Row>
            <Row>
              <Input
                type="number"
                min="0"
                name="free_vacancy_qty"
                label="Vagas gratuitas:"
                required
              />
              <Input
                type="number"
                min="0"
                name="total_vacancy_qty"
                label="Total de vagas:"
                required
              />
              <Input
                type="number"
                min="0"
                name="keep_vacancy_qty"
                label="Manutenção:"
                required
              />
            </Row>
            <Row>
              <Select
                name="family_payment"
                label="Pagamento da família:"
                options={simNaoOptions}
              />
              <Select
                name="donation"
                label="Doações:"
                options={simNaoOptions}
              />
              <Select
                name="covenants"
                label="Convênios:"
                options={simNaoOptions}
              />
            </Row>
            <Row>
              <Input name="sanitary_document" label="Alvará sanitário:" />
              <Select name="cnas" label="CNAS:" options={simNaoOptions} />
            </Row>
            <Row>
              <Select
                name="conen_comad"
                label="CONEN/COMAD:"
                options={simNaoOptions}
              />
              <Input
                name="another_register"
                label="Outros registros (Se houver):"
              />
            </Row>
            <Row>
              <Input name="federal_law" label="Lei federal (Se houver):" />
              <Input name="state_law" label="Lei estadual (Se houver):" />
            </Row>
            <Row>
              <Input name="municipal_law" label="Lei municipal (Se houver):" />
            </Row>
            <Row>
              <Input
                name="expert_name"
                label="Nome do responsável técnico:"
                required
              />
              <Input
                name="expert_graduation"
                label="Formação do técnico:"
                required
              />
            </Row>
            <Row>
              <Textarea name="methodology" label="Metodologia:" />
            </Row>
            <Row>
              <Input
                name="manager_name"
                label="Nome do representante:"
                required
              />
              <Input
                name="manager_role"
                label="Função do representante:"
                required
              />
            </Row>
            <Row>
              <Input
                type="date"
                name="manager_birth_date"
                label="Data de nascimento:"
                required
              />
              <MaskedInput
                type="cpf"
                name="manager_cpf"
                label="CPF:"
                required
              />
              <MaskedInput type="rg" name="manager_rg" label="RG:" required />
            </Row>
            <Row>
              <MaskedInput
                type="telefone"
                name="manager_phone"
                label="Telefone:"
              />
              <Input name="manager_email" label="E-mail:" />
            </Row>
            <Row>
              <Textarea
                name="manager_need"
                label="Maior necessidade do representante:"
              />
            </Row>
            <Row>
              <Select
                name="know_sobriedade"
                label="Conhece o Sobriedade:"
                options={simNaoOptions}
              />
            </Row>
            <Row>
              <MaskedInput
                type="cep"
                name="zipcode"
                label="CEP:"
                onChange={handleCepChange}
                loading={addressLoading}
              />
              <Select
                name="uf"
                label="Estado:"
                options={statesOptions}
                onChange={handleUfChange}
                required
              />
              <Select
                name="city_id"
                label="Cidade:"
                options={citiesOptions}
                required
              />
            </Row>
            <Row>
              <Input name="address_street" label="Logradouro:" />
            </Row>
            <Row>
              <Input name="address_number" label="Número:" />
              <Input name="address_neighborhood" label="Bairro:" />
            </Row>
            {(loading && <Loading />) || (
              <button type="submit">Cadastrar</button>
            )}
          </Form>
        </S.FormWrapper>
      </S.Wrapper>
    </S.Container>
  );
}

export default Register;
