import styled from "styled-components";
import { mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 720px;
  height: 378px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px ${colors.gray8};
  background-color: ${colors.white};
  padding: 50px;

  ${mixinRespondTo.md`
  padding: 18px;
  `}

  &:not(:last-child) {
    margin-bottom: 42px;
  }
`;
export const Title = styled.h1`
  font-family: "CircularMedium";
  font-size: 2.4rem;
  font-weight: 500;
  text-align: left;
  color: ${colors.blue3};
`;
export const Contact = styled.div`
  & > p {
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    text-align: left;
    color: ${colors.gray3};
  }
`;
export const Meeting = styled.h1`
  font-family: "CircularBook";
  font-size: 2rem;
  font-weight: normal;
  text-align: left;
  color: ${colors.red1};

  & > strong {
    font-family: "CircularMedium";
    font-weight: 500;
    color: ${colors.black};
    ${mixinRespondTo.md`
      display: block;
    `}
  }
`;
export const Item = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  text-align: left;
  color: ${colors.gray3};

  & > strong {
    display: block;
    font-size: 1.7rem;
    font-weight: 500;
    color: ${colors.black};
  }
`;
