import styled from "styled-components";
import { colors } from "@/styles/variables";
export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 28px 10px;

  &:not(:last-child) {
    border-bottom: solid 1px ${colors.gray5};
  }

  & > svg {
    width: 31.5px;
    height: 41.4px;
    margin-right: 21px;
    color: ${colors.red1};
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const Title = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: left;
  max-width: 271px;
  width: 100%;
`;

export const Link = styled.a`
  opacity: 0.9;
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: left;
  color: ${colors.blue3};
  margin-top: 16px;
  & > svg {
    margin-right: 8px;
  }
`;
