import React from "react";
import { useHistory } from "react-router-dom";

import { useGoTop } from "@/hooks/useGoTop";

import Banner from "@/components/Banner";
import BannerRegisterImg from "@/assets/images/banner/BannerCurso.jpg";
import { Button } from "@/styles/components/Button";

import * as S from "./styles";

function RegistrationCompleted() {
  const history = useHistory();

  useGoTop();

  return (
    <S.Container>
      <Banner
        image={BannerRegisterImg}
        title="Filiar Comunidade"
        description="Cadastro de Pré Filiação da Comunidade Terapêutica Parceira FGAS - Federação dos Grupos de Autoajuda da Sobriedade"
      />
      <S.Wrapper>
        <S.Title>
          Cadastro
          <br />
          Realizado!
        </S.Title>
        <S.SubTitle>
          Avaliaremos sua pré-filiação e em breve entraremos em contato pelos
          telefones ou e-mails informados para dar continuidade ao processo!
        </S.SubTitle>
        <Button to="/">Voltar à página inicial</Button>
      </S.Wrapper>
    </S.Container>
  );
}

export default RegistrationCompleted;
