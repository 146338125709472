import styled from "styled-components";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  max-width: 720px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 64px;
`;
export const Title = styled.h1`
  font-family: "CircularBlack", sans-serif;
  font-size: 3.8rem;
  font-weight: 900;
  letter-spacing: 0.38px;
  text-align: center;
  color: ${colors.blue3};
`;
export const List = styled.ul`
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  text-align: center;
  color: ${colors.gray3};
`;
export const ListItem = styled.li`
  padding-top: 24px;
`;
