import styled from "styled-components";
import { Link } from "react-router-dom";
import { mixinWrapper, mixinRespondTo } from "../../styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  ${mixinWrapper};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 2.4rem;
  left: 0;
  right: 0;
  z-index: 3;
`;

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    flex: 1;
    margin-right: 1.6rem;
    width: 57px;
    height: 93px;
  }

  & > h1 {
    font-family: "CircularMedium";
    font-size: 2rem;
    font-weight: 500;
    text-align: left;
    color: ${colors.white};
    max-width: 105px;
  }

  @media screen and (max-width: 1080px) {
    & > h1 {
      font-size: 1.8rem;
    }
  }
`;

export const Navbar = styled.div`
  ${mixinRespondTo.md`
     display: none;
     `};
  width: 80%;
  overflow: hidden;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const NavLink = styled(Link)`
  float: left;
  width: max-content;
  text-align: center;
  padding: 14px 12px;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  color: ${colors.white};
  white-space: nowrap;
  margin-left: 15px;

  &:hover {
    color: ${colors.yellow3};
  }

  @media screen and (max-width: 1124px) {
    padding: 14px 8px;
    font-size: 1.2rem;
  }
`;

export const ExternalLink = styled.a`
  float: left;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: ${colors.white};
  white-space: nowrap;

  &:hover {
    color: ${colors.yellow3};
  }

  @media screen and (max-width: 1124px) {
    padding: 14px 8px;
    font-size: 1.2rem;
  }
`;

export const Dropdown = styled.div`
  float: left;

  & > button {
    position: relative;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    white-space: nowrap;
    color: ${colors.white};
    background-color: transparent;
    margin: 0;

    @media screen and (max-width: 1124px) {
      padding: 14px 12px;
      font-size: 1.2rem;
    }
    &:hover {
      color: ${colors.yellow3};
    }

    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid ${colors.yellow2};
    }
  }

  &:hover > div {
    display: block;
  }

  &:hover > button {
    &::after {
      cursor: pointer;
      content: "";
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 10px solid ${colors.yellow3};
    }
  }
`;
export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  min-width: 200px;
  background: ${colors.yellow3};
  padding: 20px 32px;
  box-shadow: 0 8px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  z-index: 1;
`;
export const DropdownLink = styled(Link)`
  float: none;
  color: ${colors.black};
  text-decoration: none;
  display: block;
  text-align: left;
  padding: 8px 0;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  &:hover {
    color: ${colors.white};
  }
`;
