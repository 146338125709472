import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ListEventsActions } from "store/ducks/events";
import { useGoTop } from "@/hooks/useGoTop";
import Banner from "@/components/Banner";
import BannerEventsImg from "@/assets/images/banner/BannerCalendario.jpg";
import EventCard from "./EventCard";

import * as S from "./styles";

import Loading from "@/components/Loading";
import Paginator from "@/components/Paginator";

function Events() {
  const dispatch = useDispatch();

  useGoTop();

  const {
    data: events,
    loading,
    pagination,
  } = useSelector((state) => state.listEvents);
  const [query, setQuery] = useState({
    page: 1,
    limit: 12,
  });

  const handlePageChange = useCallback(
    (page) => {
      setQuery((state) => ({ ...state, page }));
    },
    [setQuery]
  );

  function renderEventsList() {
    if (loading) return <Loading />;
    return (
      <>
        <S.GroupEvents>
          {events.map((e) => (
            <EventCard event={e} key={e.id} />
          ))}
        </S.GroupEvents>
        <Paginator pagination={pagination} onPageChange={handlePageChange} />
      </>
    );
  }

  const fetchEventsCB = useCallback(() => {
    dispatch(ListEventsActions.request(query));
  }, [query, dispatch]);

  useEffect(() => {
    fetchEventsCB();
  }, [fetchEventsCB]);

  useEffect(() => {
    return () => {
      dispatch(ListEventsActions.reset());
    };
  }, [dispatch]);

  return (
    <S.Container>
      <Banner
        image={BannerEventsImg}
        title="Eventos"
        description="Confira os próximos eventos da Pastoral da Sobriedade."
        descriptionWidth={220}
      />
      <S.Wrapper>
        <S.Title>Próximos Eventos</S.Title>
      </S.Wrapper>
      {renderEventsList()}
    </S.Container>
  );
}

export default Events;
