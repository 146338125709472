import styled from "styled-components";
import { colors } from "@/styles/variables";
export const Container = styled.figure`
  min-height: max-content;
  border: solid 1px ${colors.gray5};
  background-color: ${colors.white};
  padding: 32px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
export const Image = styled.img`
  max-width: 100%;
  margin-bottom: 24px;
  @media screen and (max-width: 960px) {
    max-height: 300px;
  }
  @media screen and (max-width: 840px) {
    max-height: 300px;
  }
`;
export const Title = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 24px;
`;
export const Price = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 1.7rem;
  font-weight: 500;
  text-align: center;
  color: ${colors.red1};
`;
