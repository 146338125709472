import { call, put } from "redux-saga/effects";
import { api, queryBuilder } from "services";
import { RequestErrorHandler } from "utils";

import { SelectCitiesActions } from "store/ducks/cities";

export function* selectCitiesRequest(action) {
  try {
    const { query = {} } = action;
    const queryString = queryBuilder({ ...query, combo: 1 });

    const { data } = yield call(api.get, `city?${queryString}`);

    yield put(SelectCitiesActions.success(data));
  } catch (error) {
    const { errorMessage } = RequestErrorHandler(error);
    yield put(SelectCitiesActions.failure(errorMessage));
  }
}
