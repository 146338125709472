import styled from "styled-components";
import { Link } from "react-router-dom";

import { mixinWrapper } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div``;

export const Wrapper = styled.div`
  ${mixinWrapper};
  padding: 52px 0;
`;

export const FiltersContainer = styled.div``;

export const FormRow = styled.div.attrs({
  className: "form-row",
})`
  width: 100%;
  display: flex;
  flex-direction: row;
  .field-container {
    flex: 1 1 100%;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const StatisticsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 64px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const MainContent = styled.div`
  flex: 1 1 100%;
`;

export const SideContent = styled.div`
  @media screen and (min-width: 1001px) {
    width: 330px;
    margin-left: 60px;
  }
  @media screen and (max-width: 1000px) {
    margin-top: 64px;
  }
`;

export const SideContentHeader = styled.div`
  border-bottom: 4px solid ${colors.blue5};
`;

export const SideContentRow = styled.div`
  padding: 30px 0px;
  border-bottom: 1px solid #b2b2b2;

  h4 {
    color: #000;
    font-family: "Roboto", sans-serif;
    margin-bottom: 12px;
    font-size: 17px;
  }
`;

export const SideRowLink = styled(Link)`
  color: ${colors.blue3};
  font-family: "RobotoBold", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const Title = styled.h2`
  font-family: "CircularMedium";
  font-size: 26px;
  margin-bottom: 42px;
`;

export const CardsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 42px;
  margin-bottom: 42px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.div.attrs({ className: "card" })`
  border-radius: 6px;
  background-color: ${colors.blue3};
  color: #fff;
  padding: 32px;
  &.dark {
    background-color: ${colors.blue5};
  }
`;

export const CardTitle = styled.h4`
  font-family: "CircularBold";
  font-size: 18px;
  margin-bottom: 24px;
`;

export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardLabel = styled.label`
  font-size: 15px;
  font-family: "Roboto", sans-serif;
`;
export const CardValue = styled.span`
  font-size: 22px;
  font-family: "Roboto", sans-serif;
`;
