import React from "react";
import { moeda } from "@/formatters";
import { Container, Image, Title, Price } from "./styles";

function Book({ image, title, price }) {
  return (
    <Container>
      <Image src={image} />
      <Title>{title}</Title>
      {/* <Price>{moeda(price)}</Price> */}
    </Container>
  );
}

export default Book;
