import styled from "styled-components";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 720px;
  height: 300px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px ${colors.gray8};
  background-color: ${colors.white};
  padding: 40px;

  &:not(:last-child) {
    margin-bottom: 42px;
  }
`;
export const Title = styled.h1`
  font-family: "CircularMedium", sans-serif;
  font-size: 2.4rem;
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
  color: ${colors.blue3};
`;
export const SubTitle = styled.p`
  font-family: "CircularBook", sans-serif;
  font-size: 1.8rem;
  font-weight: normal;
  text-transform: capitalize;
  color: ${colors.blue12};
`;
export const Text = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  text-transform: capitalize;
  color: ${colors.gray3};

  & > strong {
    display: block;
    font-family: "Roboto", sans-serif;
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 12px;
    color: ${colors.black};
  }
`;
