import styled from "styled-components";
import { mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 531px;
  margin: auto;

  ${mixinRespondTo.md`
    width: 80%;
  `}
`;
export const Title = styled.h1`
  font-family: "CircularMedium";
  font-size: 3.8rem;
  font-weight: 500;
  text-align: center;
  max-width: 411px;
  margin: 21px 0 32px;

  & > strong {
    font-weight: 500;
    color: ${colors.blue3};
  }

  ${mixinRespondTo.md`
    font-size: 3.2rem;
  `}
`;
export const Description = styled.p`
  font-family: "Roboto";
  font-size: 1.6rem;
  font-weight: normal;
  text-align: center;
  color: ${colors.gray3};
`;
