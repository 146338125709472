import styled from "styled-components";
import { mixinWrapper } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div``;
export const Wrapper = styled.div`
  ${mixinWrapper}
  padding-top: 75px;
`;
export const WrapperCol = styled.div`
  max-width: 720px;
  margin: 0 auto;

  & > hr {
    width: 100%;
    height: 4px;
    margin: 42px 0;
    background-color: ${colors.gray1};
    border: none;
  }
`;

export const Title = styled.h1`
  font-family: "CircularBook", sans-serif;
  font-size: 3.6rem;
  font-weight: normal;
  color: ${colors.black};
  margin: 32px 0 24px;
`;

export const Tag = styled.p`
  display: flex;
  align-items: center;
  font-family: "CircularMedium", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${colors.blue3};
  cursor: pointer;

  & > svg {
    margin-right: 8px;
    cursor: pointer;
  }

  & > span {
    margin-left: 19px;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    color: ${colors.gray3};
  }
`;

export const Picture = styled.div`
  width: max-content;
  margin: 32px auto 56px auto;
  display: block;
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

export const Text = styled.div`
  p {
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 1.5;
    text-align: left;
    color: ${colors.gray3};
    margin-bottom: 35px;
  }
`;

export const SocialGroup = styled.div`
  display: flex;
`;
