import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ListNewsActions } from "store/ducks/news";
import { useGoTop } from "@/hooks/useGoTop";
import Banner from "@/components/Banner";
import NewsCard from "@/components/NewsCard";
import Paginator from "@/components/Paginator";
import BannerNewsImg from "@/assets/images/banner/BannerStatistics.jpg";

import * as S from "./styles";

import Loading from "@/components/Loading";

function News() {
  const {
    data: listNewsData,
    loading: listNewsLoading,
    pagination,
  } = useSelector((state) => state.listNews);

  useGoTop();
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    page: 1,
    limit: 12,
  });

  const handlePageChange = useCallback(
    (page) => {
      setQuery((state) => ({ ...state, page }));
    },
    [setQuery]
  );

  function renderNewsList() {
    if (listNewsLoading) return <Loading />;
    return (
      <>
        <S.GroupNewsCard>
          {listNewsData.map((n) => (
            <NewsCard news={n} key={n.id} />
          ))}
        </S.GroupNewsCard>
        <Paginator pagination={pagination} onPageChange={handlePageChange} />
      </>
    );
  }

  const fetchNewsCB = useCallback(() => {
    dispatch(ListNewsActions.request({ ...query }));
  }, [dispatch, query]);

  useEffect(() => {
    fetchNewsCB();
  }, [fetchNewsCB]);

  useEffect(() => {
    return () => {
      dispatch(ListNewsActions.reset());
    };
  }, [dispatch]);

  return (
    <S.Container>
      <Banner
        image={BannerNewsImg}
        title="Notícias"
        description="Confira as últimas novidades da Pastoral da Sobriedade."
        descriptionWidth={220}
      />

      <S.Wrapper>
        <S.Title>Últimas notícias</S.Title>
        <S.WrapperGroup>{renderNewsList()}</S.WrapperGroup>
      </S.Wrapper>
    </S.Container>
  );
}

export default News;
