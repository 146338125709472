import React, { useState, useEffect } from "react";
import MenuMobile from "./MenuMobile";

import {
  Container,
  Navbar,
  NavLink,
  Dropdown,
  DropdownContent,
  DropdownLink,
  ExternalLink,
  Logo,
} from "./styles";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";

const menu = [
  {
    title: "Pastoral",
    link: "",
    itens: [
      { title: "Sobre a Pastoral", link: "/sobre" },
      { title: "Cursos de Formação", link: "/sobre/curso-formacao" },
      { title: "Terço da Sobriedade", link: "/sobre/terco-sobriedade" },
      { title: "Arquivos e Materiais", link: "/sobre/arquivos-materiais" },
      { title: "Estatísticas", link: "/sobre/estatisticas" },
    ],
  },
  { title: "Os 12 Passos", link: "/12-passos", itens: [] },
  {
    title: "Grupos de Ajuda",
    link: "",
    itens: [
      { title: "Como funciona", link: "/grupos/como-funciona" },
      { title: "Encontre um Grupo", link: "/grupos/encontrar-grupo" },
      { title: "Como implantar", link: "/grupos/como-implantar" },
    ],
  },
  // {
  //   title: "Novidades",
  //   link: "",
  //   itens: [
  //     { title: "Notícias", link: "/novidades/noticias" },
  //     { title: "Eventos", link: "/novidades/eventos" },
  //   ],
  // },
  { title: "Notícias", link: "/novidades/noticias", itens: [] },
  { title: "Eventos", link: "/novidades/eventos", itens: [] },
  {
    title: "Comunidades Terapêuticas Parceiras",
    link: "",
    itens: [
      {
        title: "Conheça nossas Comunidades Parceiras",
        link: "/comunidades/nossas-comunidades",
      },
      {
        title: "Cadastro de Pré-Filiação de Comunidade Terapêutica Parceira",
        link: "/comunidades/cadastro",
      },
    ],
  },
  {
    title: "Área Restrita",
    link: "http://sobriedade.org.br/dashboard/",
    external: true,
    itens: [],
  },
];

function Header() {
  function buildLinkComponent(item, i) {
    const { title, link, external = false } = item;
    if (external) {
      return (
        <ExternalLink key={i} target={"_blank"} href={link}>
          {title}
        </ExternalLink>
      );
    }
    return (
      <NavLink key={i} to={link}>
        {title}
      </NavLink>
    );
  }

  return (
    <Container>
      <Logo to="/">
        <LogoIcon />
        <h1>Pastoral da Sobriedade</h1>
      </Logo>

      <Navbar>
        {menu.map((item, i) =>
          item.itens.length === 0 ? (
            buildLinkComponent(item, i)
          ) : (
            <Dropdown key={i}>
              <button className="dropdown-button">{item.title}</button>
              <DropdownContent>
                {item.itens.map((subitem, index) => {
                  const { link, title } = subitem;
                  return (
                    <DropdownLink to={link} key={index}>
                      {title}
                    </DropdownLink>
                  );
                })}
              </DropdownContent>
            </Dropdown>
          )
        )}
      </Navbar>
      <MenuMobile menu={menu} />
    </Container>
  );
}

export default Header;
