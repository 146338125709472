import React, { useRef, useEffect, useState, useCallback } from "react";
import ReactInputMask from "react-input-mask";
import { useField } from "@unform/core";
import { Container } from "./styles";

const formatChars = { 9: /[0-9]/, "?": /[0-9]/, "*": /[0-9a-zA-Z]/ };

const maskConfigs = {
  telefone: {
    mask: "(99) 9999-9999?",
    formatChars,
  },
  celular: {
    mask: "(99) 99999-9999",
    formatChars,
  },
  telefoneInternacional: {
    mask: "99999999999999999999",
    formatChars,
  },
  cpf: {
    mask: "999.999.999-99",
    formatChars,
  },
  cnpj: {
    mask: "99.999.999/9999-99",
    formatChars,
  },
  rg: {
    mask: "99.999.999-9",
    formatChars,
  },
  cep: {
    mask: "99999-999",
    formatChars,
  },
};

const InputMask = ({ name, label, type, required, ...rest }) => {
  const inputRef = useRef(null);
  const [currentMaskConfig, setCurrentMaskConfig] = useState(type);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  function telefoneTransformer(newState) {
    const length = newState.value.length;
    if (length > 14) {
      setCurrentMaskConfig("celular");
    } else {
      setCurrentMaskConfig("telefone");
    }
  }

  function handleBeforeMaskedValueChange(newState, oldState, userInput) {
    if (type === "telefone") {
      telefoneTransformer(newState);
    }
    return newState;
  }

  const setCurrentMaskConfigCB = useCallback(() => {
    if (!type) return null;
    setCurrentMaskConfig(type);
  }, [type, setCurrentMaskConfig]);

  const setInputValueCB = useCallback(() => {
    if (inputRef.current && defaultValue) {
      inputRef.current.setInputValue(defaultValue);
    }
  }, [defaultValue, inputRef]);

  useEffect(() => {
    setCurrentMaskConfigCB();
  }, [setCurrentMaskConfigCB]);

  useEffect(() => {
    setInputValueCB();
  }, [setInputValueCB]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      setValue(ref, value) {
        ref.setInputValue(value || "");
      },
      clearValue(ref) {
        ref.setInputValue("");
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container error={error}>
      <label htmlFor={fieldName}>
        {label}
        {required && <span>*</span>}
      </label>
      <ReactInputMask
        ref={inputRef}
        name={fieldName}
        defaultValue={defaultValue}
        mask={maskConfigs[currentMaskConfig].mask}
        formatChars={maskConfigs[currentMaskConfig].formatChars}
        beforeMaskedValueChange={handleBeforeMaskedValueChange}
        maskChar=""
        {...rest}
      />
      {error && <span className="error">{error}</span>}
    </Container>
  );
};

export default InputMask;
