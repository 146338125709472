import styled, { css } from "styled-components";
import { shade } from "polished";
import { colors } from "@/styles/variables";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 24px;
  text-align: center;
`;

export const Button = styled.a`
  padding: 18px 24px;
  border-radius: 4px;
  background-color: ${colors.red1};
  font-family: "CircularMedium";
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
  color: ${colors.white};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => shade(0.2, colors.red1)};
  }

  ${(props) =>
    props.light &&
    css`
      background-color: ${colors.white};
      color: ${colors.blue2};

      &:hover {
        background-color: ${(props) => shade(0.2, colors.white)};
      }
    `}
`;
