import styled from "styled-components";
import { mixinWrapper, mixinRespondTo } from "../../styles/tools";
import { colors } from "@/styles/variables";
export const Container = styled.div`
  position: relative;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 571px;

  ${mixinRespondTo.md`
    background-position: center;

  `}

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.87;
    background-image: linear-gradient(
      to right,
      #000000 22%,
      rgba(0, 0, 0, 0.36) 67%
    );
  }
`;

export const Wrapper = styled.div`
  ${mixinWrapper};

  padding: 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;

  & > h1 {
    position: relative;
    font-family: "CircularMedium", sans-serif;
    font-size: 3.8rem;
    font-weight: 500;
    text-align: left;
    color: ${colors.white};
    max-width: 457px;

    ${mixinRespondTo.md`
      font-size: 3.4rem;
    `}
  }

  & > h1::after {
    content: "";
    position: absolute;
    top: -21px;
    left: 0;
    width: 63px;
    height: 4px;
    background-color: ${colors.yellow2};
  }

  & > p {
    opacity: 0.9;
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: ${colors.white};
    max-width: 476px;
    margin: 32px 0;

    ${mixinRespondTo.md`
       font-size: 1.9rem;
    `}
  }

  & > div {
    z-index: 2;
    display: flex;

    & > a:first-child {
      margin-right: 24px;
      ${mixinRespondTo.md`
       margin-right: 0;
      margin-bottom: 16px;
   
  `}
    }

    ${mixinRespondTo.md`
      flex-direction: column;
      
    `}
  }

  & > a {
    z-index: 2;
  }
`;
