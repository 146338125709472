import styled from "styled-components";
import { colors } from "@/styles/variables";

export const Button = styled.a`
  width: 292px;
  height: 56px;
  border-radius: 4px;
  background-color: ${colors.blue3};
  font-family: "CircularMedium";
  font-size: 1.8rem;
  font-weight: 500;
  color: ${colors.white};
  white-space: nowrap;
  padding: 19px 29px;
`;
