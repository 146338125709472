import styled from "styled-components";
import { mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";
export const Container = styled.div`
  ${mixinRespondTo.md`
    order: 2;
     
  `}
`;
export const Title = styled.h1`
  font-family: "CircularMedium", sans-serif;
  font-size: 3rem;
  font-weight: 500;
  text-align: left;
`;
export const DatasheetGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;
  background: ${colors.white};
  padding: 27px;
  margin-top: 40px;
  max-width: 721px;
  width: 100%;

  ${mixinRespondTo.md`
    grid-template-columns: repeat(1, 1fr);
    padding: 0 27px;
  `}
`;
