import React from "react";

import { Container, Title, List, ListItem } from "./styles";

function SectionFeatures() {
  return (
    <Container>
      <Title>
        Características dos <strong>Grupos de Autoajuda</strong> da Pastoral da
        Sobriedade
      </Title>

      <List>
        <ListItem>É pastoral;</ListItem>
        <ListItem>É um programa de vida nova - terapia do amor;</ListItem>
        <ListItem>É uma ação evangelizadora;</ListItem>
        <ListItem>Não se limita à dependência química;</ListItem>
        <ListItem>Conduz a uma reflexão pessoal;</ListItem>
        <ListItem>Não permite aconselhamento durante a reunião;</ListItem>
        <ListItem>Respeita a individualidade;</ListItem>
        <ListItem>Trabalha o respeito e a disciplina;</ListItem>
        <ListItem>
          O grupo de partilha não separa as pessoas por sua condição, mas por
          parentesco;
        </ListItem>
        <ListItem>
          Não se limita a bloquear o uso das drogas, mas propõe a redescoberta
          da dignidade e do verdadeiro sentido da vida.
        </ListItem>
      </List>
    </Container>
  );
}

export default SectionFeatures;
