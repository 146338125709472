import React from "react";

import { Container, Header, Body } from "./styles";

function StepCard({ item, title, text }) {
  return (
    <Container>
      <Header>
        <strong>{item}</strong>
        {title}
      </Header>
      <Body>{text}</Body>
    </Container>
  );
}

export default StepCard;
