import styled from "styled-components";
import { mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  width: 100%;
  max-width: 340px;
  border-radius: 8px;
  background-color: ${colors.gray2};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 35px 40px;

  ${mixinRespondTo.md`
   padding: 15px 20px;

     height: 260px;
    `}
`;

export const Header = styled.h1`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "CircularBook", sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  letter-spacing: 0.22px;
  text-align: left;
  margin-bottom: 24px;
  color: ${colors.blue3};

  & > strong {
    font-family: "CircularBlack", sans-serif;
    font-size: 3.8rem;
    font-weight: 900;
    letter-spacing: 0.38px;
    text-align: center;
    color: ${colors.black};
    margin-right: 22px;
  }
`;

export const Body = styled.p`
  font-family: "Georgia", sans-serif;
  font-size: 1.8rem;
  font-weight: normal;
  text-align: left;
  color: ${colors.gray11};

  ${mixinRespondTo.md`
    font-size: 2rem;
  `}
`;
