import { call, put } from "redux-saga/effects";
import { api, queryBuilder, notify } from "services";
import { RequestErrorHandler } from "utils";
import { SelectDiocesesActions } from "store/ducks/dioceses";

export function* selectDiocesesRequest(action) {
  const { query = {} } = action;
  try {
    const queryString = queryBuilder({ ...query, combo: 1 });
    const { data } = yield call(api.get, `/open/diocese?${queryString}`);
    yield put(SelectDiocesesActions.success(data));
  } catch (error) {
    const { errorMessage } = RequestErrorHandler(error);
    notify("error", errorMessage);
    yield put(SelectDiocesesActions.failure(errorMessage));
  }
}
