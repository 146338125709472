import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { mixinWrapper, mixinRespondTo } from "../../styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: ${colors.blue1};
`;

export const Wrapper = styled.div`
  ${mixinWrapper};
  display: flex;
  padding: 70px 0 50px;

  ${mixinRespondTo.md`
    flex-direction: column;
  `}
`;

export const Bio = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-height: 278px;
  max-width: 308px;
  width: 100%;
  height: 100%;
  margin-right: 70px;
`;

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 220px;
  & > svg {
    flex: 1;
    margin-right: 1.6rem;
    width: 57px;
    height: 93px;
  }

  & > h1 {
    font-family: "CircularMedium";
    font-size: 2.4rem;
    font-weight: 500;
    text-align: left;
    color: ${colors.white};
    max-width: 126px;

    & > strong {
      color: ${colors.red1};
    }
  }
`;

export const BioDescription = styled.p`
  max-width: 308px;
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: left;
  line-height: 1.43;
  color: ${colors.white};
  margin-top: 1.8rem;
`;

export const BioSocial = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;

  & > a {
    margin-right: 2rem;

    & > svg {
      font-size: 2rem;
      color: ${colors.white};
    }
  }
`;

export const Itens = styled.div`
  width: 100%;
  max-height: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 22px;
  column-gap: 16px;

  ${mixinRespondTo.md`
    flex-direction: column;
    max-height: 100%;
  `}
`;

export const Title = styled(Link)`
  font-family: "CircularBlack";
  font-size: 1.8rem;
  font-weight: 900;
  text-align: left;
  color: ${colors.blue4};
  margin-bottom: 32px;

  ${mixinRespondTo.md`
    margin: 12px 0;
  `}

  ${(props) =>
    props.color === "red" &&
    css`
      color: ${colors.red1};
      margin: 32px 0 0;
    `}
`;

export const AreaRestritaLink = styled.a`
  font-family: "CircularBlack";
  font-size: 1.8rem;
  font-weight: 900;
  text-align: left;
  color: ${colors.red1};
  margin: 32px 0 0;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 1 1 33.33%;
`;

export const ListWrap = styled.ul`
  display: flex;
  flex-direction: column;
  ${mixinRespondTo.md`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 100px;

    & > li {
      margin-right: 28px;
    }

  `}
`;

export const ListTitle = styled.h1`
  font-family: "CircularBlack";
  font-size: 1.8rem;
  font-weight: 900;
  text-align: left;
  color: ${colors.blue4};
  margin: 9px 0;
  &:first-child {
    margin: 0 0 9px;
  }

  ${mixinRespondTo.md`
      margin-top: 12px 0;
      &:first-child {
        margin: 12px 0;
      }
    `}
  ${(props) =>
    props.space === "top" &&
    css`
      margin-top: 32px;

      ${mixinRespondTo.md`
        margin-top: 12px;
      `}
    `}
`;

export const ListItem = styled.li`
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  text-align: left;
  color: ${colors.blue6};
  margin: 6px 0;
  & > a {
    color: ${colors.blue6};
  }
`;

export const SubFooter = styled.div`
  ${mixinWrapper};
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 22px 0;
  border-top: 1px solid ${colors.blue7};
  font-family: "Roboto" sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  color: ${colors.blue7};

  ${mixinRespondTo.md`
    align-items: flex-start;
    flex-direction: column;
  `}
`;

export const SubFooterLeft = styled.div`
  text-align: left;
  a {
    color: ${colors.blue7};
  }
`;

export const SubFooterRight = styled.div`
  text-align: right;

  ${mixinRespondTo.md`
    text-align: left;
    margin-top: 24px;
  `}
`;

export const FooterAllRights = styled.div`
  display: block;
  text-align: center;
  padding: 15px 0;
  a {
    color: ${colors.gray7};
    font-size: small;
  }
  a:hover {
    color: ${colors.gray7};
    text-decoration: underline;
  }
`;
