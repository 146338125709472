import React from "react";
import { useGoTop } from "@/hooks/useGoTop";
import Banner from "@/components/Banner";
import JumbotronCenter from "@/components/JumbotronCenter";
import { ReactComponent as CrossIcon } from "@/assets/icons/cross.svg";
import BannerImplantImg from "@/assets/images/banner/BannerCurso.jpg";
import Jumbotron4Img from "@/assets/images/jumbotron/Jumbotron4.jpg";
import SectionIdentity from "./SectionIdentity";
import SectionFeatures from "./SectionFeatures";
import { Container } from "./styles";

function Implant() {
  useGoTop();
  return (
    <Container>
      <Banner
        image={BannerImplantImg}
        title="Como Implantar"
        description="Saiba como implantar a Pastoral da Sobriedade na sua Paróquia."
        descriptionWidth={380}
      />

      <SectionIdentity />

      <JumbotronCenter
        image={Jumbotron4Img}
        titleWidth={656}
        descriptionWidth={548}>
        <CrossIcon />
        <h1>
          O Grupo de Autoajuda é a consolidação do desenvolvimento do trabalho
          da Pastoral da Sobriedade.
        </h1>
        <p>
          O Grupo de Autoajuda pretende levar o co-dependente e o dependente a
          fazerem a terapia do amor.
        </p>
      </JumbotronCenter>

      <SectionFeatures />
    </Container>
  );
}

export default Implant;
