import styled from "styled-components";
import { colors } from "styles/variables";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 0;
`;

export const Button = styled.button`
  width: 47px;
  height: 47px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ active }) => (active ? colors.white : colors.blue3)};
  border: solid 1px ${colors.gray9};
  background-color: ${({ active }) => (active ? colors.blue3 : "transparent")};
  margin-right: 8px;
  font-size: 1.6rem;

  transition: 300ms ease;

  &:hover {
    background-color: ${colors.blue3};
    color: ${colors.white};
  }

  &:last-child {
    margin-right: 0;
  }
`;
