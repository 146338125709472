import styled from "styled-components";
import { mixinWrapper, mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 635px;

  ${mixinRespondTo.md`
   height: 435px;
  `}

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${colors.black};
    opacity: 0.5;
  }
`;

export const Wrapper = styled.div`
  ${mixinWrapper};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;

  & > h1 {
    max-width: ${(props) =>
      props.titleWidth ? `${props.titleWidth}px` : "618px"};
    font-family: "Georgia", sans-serif;
    font-size: 3.8rem;
    font-weight: normal;
    text-align: center;
    color: ${colors.white};
    margin: 24px 0 20px;

    ${mixinRespondTo.md`
  font-size: 3.2rem;
  `}
  }

  & > p {
    max-width: ${(props) =>
      props.descriptionWidth ? `${props.descriptionWidth}px` : "531px"};
    opacity: 0.9;
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    text-align: center;
    color: ${colors.white};
  }
`;
