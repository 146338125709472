import React from "react";
import { useMemo } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";

import * as S from "./styles";

const perc = (value) => {
  return `${value.toFixed(2)}%`;
};

const DrugsChart = ({ groupReports }) => {
  const chartData = useMemo(() => {
    const {
      nicotine,
      alcohol,
      cocaine,
      marijuana,
      crack,
      otherDrugs,
      otherAddictions,
    } = groupReports;

    return [
      {
        name: "Nicotina",
        value: nicotine.quant,
        percentage: nicotine.percentage,
        color: "#fb9229",
      },
      {
        name: "Alcool",
        value: alcohol.quant,
        percentage: alcohol.percentage,
        color: "#261f77",
      },
      {
        name: "Cocaína",
        value: cocaine.quant,
        percentage: cocaine.percentage,
        color: "#00989a",
      },
      {
        name: "Maconha",
        value: marijuana.quant,
        percentage: marijuana.percentage,
        color: "#68c8e4",
      },
      {
        name: "Crack",
        value: crack.quant,
        percentage: crack.percentage,
        color: "#424242",
      },
      {
        name: "Outra dependência química",
        value: otherDrugs.quant,
        percentage: otherDrugs.percentage,
        color: "#fccbe4",
      },
      {
        name: "Outro tipo de dependência",
        value: otherAddictions.quant,
        percentage: otherAddictions.percentage,
        color: "#fd3a1a",
      },
    ];
  }, [groupReports]);

  const chartHasRelevantData = useMemo(() => {
    return chartData.some((item) => item.value > 0);
  }, [chartData]);

  return (
    <S.MainContainer>
      <S.LegendContainer>
        <S.LegendHeader>
          <S.LegendTitle>Percentual:</S.LegendTitle>
          <S.LegendTitle>
            Uso de drogas (percepção do agente da Pastoral da Sobriedade)
          </S.LegendTitle>
        </S.LegendHeader>
        {chartData.map((item) => (
          <S.LegendItem key={item.name}>
            <S.LegendPercentage>{perc(item.percentage)}</S.LegendPercentage>
            <S.LegendBubble style={{ backgroundColor: item.color }} />
            <S.LegendLabel>{item.name}</S.LegendLabel>
          </S.LegendItem>
        ))}
      </S.LegendContainer>
      {chartHasRelevantData && (
        <PieChart width={300} height={300}>
          <Pie
            data={chartData}
            cx={150}
            cy={150}
            labelLine={false}
            innerRadius={80}
            outerRadius={130}
            paddingAngle={4}>
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      )}
    </S.MainContainer>
  );
};

export default DrugsChart;
