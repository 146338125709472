import styled from "styled-components";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  display: none;
  ${(props) => props.active && "display: flex;"}
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 60px;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  z-index: 1000;
`;
export const CloseWarning = styled.button`
  font-size: small;
  padding: 6px 15px;
  margin-left: 20px;
  color: ${colors.white};
  background: ${colors.red1};
  border-radius: 4px;
`;

export const Text = styled.p`
  color: ${colors.white};
  font-size: small;
  a {
    color: ${colors.white};
    text-decoration: underline;
  }
`;
