import { call, put } from "redux-saga/effects";
import { api, queryBuilder, notify } from "services";
import { RequestErrorHandler } from "utils";
import { SelectParoquiasActions } from "store/ducks/paroquias";

export function* selectParoquiasRequest(action) {
  const { query = {} } = action;
  try {
    const queryString = queryBuilder({ ...query, combo: 1 });
    const { data } = yield call(api.get, `/open/paroquia?${queryString}`);
    yield put(SelectParoquiasActions.success(data));
  } catch (error) {
    const { errorMessage } = RequestErrorHandler(error);
    notify("error", errorMessage);
    yield put(SelectParoquiasActions.failure(errorMessage));
  }
}
