import styled from "styled-components";
import { mixinWrapper, mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";
export const Container = styled.div`
  background-color: ${colors.gray2};
`;
export const Wrapper = styled.div`
  ${mixinWrapper}
  padding: 75px 0;
`;
export const Title = styled.h1`
  font-family: "CircularMedium", sans-serif;
  font-size: 3rem;
  font-weight: 500;
  text-align: left;
`;
export const Description = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  text-align: left;
  color: ${colors.gray3};
  margin-top: 12px;

  & > a {
    font-weight: bold;
    color: ${colors.blue3};
  }
`;

export const BookGroup = styled.div`
  max-width: 1100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-top: 33px;

  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 840px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 85px;

  ${mixinRespondTo.md`
    flex-direction: column;
    margin-top: 45px;
  `}
`;
