import { call, put } from "redux-saga/effects";
import { api, queryBuilder } from "services";
import { RequestErrorHandler } from "utils";

import { ListCoursesActions } from "store/ducks/courses";

export function* listCoursesRequest(action) {
  try {
    const { query = {} } = action;
    const queryString = queryBuilder({ ...query, unblockedOnly: 1 });

    const { data: responseBody } = yield call(
      api.get,
      `open/course?${queryString}`
    );

    const { data, pagination } = responseBody;
    yield put(ListCoursesActions.success(data, pagination));
  } catch (error) {
    const { errorMessage } = RequestErrorHandler(error);
    yield put(ListCoursesActions.failure(errorMessage));
  }
}
