import React from "react";
import { ReactComponent as DocsIcon } from "@/assets/icons/docs.svg";
import { ReactComponent as DownloadIcon } from "@/assets/icons/download.svg";
import { Container, Title, Link } from "./styles";

function Datasheet({ title, link }) {
  return (
    <Container>
      <DocsIcon />
      <div>
        <Title>{title}</Title>
        <Link href={link} download={title}>
          <DownloadIcon /> BAIXAR
        </Link>
      </div>
    </Container>
  );
}

export default Datasheet;
