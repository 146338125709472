import React from "react";

import { Container, Wrapper } from "./styles";

function JumbotronCenter({ image, titleWidth, descriptionWidth, children }) {
  return (
    <Container src={image}>
      <Wrapper titleWidth={titleWidth} descriptionWidth={descriptionWidth}>
        {children}
      </Wrapper>
    </Container>
  );
}

export default JumbotronCenter;
