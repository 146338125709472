import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import BannerStatistics from "@/assets/images/banner/BannerStatistics.jpg";
import { LegacyStatisticsActions } from "@/store/ducks/statistics";
import { useGoTop } from "@/hooks/useGoTop";
import Banner from "@/components/Banner";
import Loading from "@/components/Loading";

import Filters from "./Filters";
import Result from "./Result";
import * as S from "./styles";

const Statistics = () => {
  const dispatch = useDispatch();

  const { data: statistics, loading: loadingStatistics } = useSelector(
    (state) => state.legacyStatistics
  );

  const fetchStatistics = useCallback(
    (query) => {
      dispatch(LegacyStatisticsActions.request(query));
    },
    [dispatch]
  );

  const SidebarComponent = useCallback(() => {
    return (
      <S.SideContent>
        <S.SideContentHeader>
          <S.Title>Outros Dados</S.Title>
        </S.SideContentHeader>
        <S.SideContentRow>
          <h4>Cursos de Capacitação</h4>
          <S.SideRowLink to="/sobre/curso-formacao">
            Confira os Cursos
          </S.SideRowLink>
        </S.SideContentRow>
        <S.SideContentRow>
          <h4>Grupos de Autoajuda</h4>
          <S.SideRowLink to="/grupos/encontrar-grupo">
            Encontre grupos
          </S.SideRowLink>
        </S.SideContentRow>
        <S.SideContentRow>
          <h4>Comunidades Terapêuticas Parceiras</h4>
          <S.SideRowLink to="/comunidades/nossas-comunidades">
            Nossas Comunidades
          </S.SideRowLink>
          <S.SideRowLink to="/comunidades/cadastro">Filie-se</S.SideRowLink>
        </S.SideContentRow>
      </S.SideContent>
    );
  }, []);

  useGoTop();

  return (
    <S.Container>
      <Banner
        image={BannerStatistics}
        title="Estatísticas"
        description="Dados consolidados das ações da Pastoral da Sobriedade pelo país"
        descriptionWidth={265}
      />
      <S.Wrapper>
        <S.MainContainer>
          <S.MainContent>
            <Filters onChange={fetchStatistics} />
            {loadingStatistics && <Loading />}
            {statistics && (
              <S.ResultContainer>
                <Result result={statistics} />
              </S.ResultContainer>
            )}
          </S.MainContent>
          <SidebarComponent />
        </S.MainContainer>
      </S.Wrapper>
    </S.Container>
  );
};

export default Statistics;
