import React from "react";
import { useGoTop } from "@/hooks/useGoTop";
import SectionTraining from "./SectionTraining";
import SectionCalendar from "./SectionCalendar";
import Banner from "@/components/Banner";
import BannerCursoImg from "@/assets/images/banner/BannerCurso.jpg";
import { Container } from "./styles";

function Course() {
  useGoTop();

  return (
    <Container>
      <Banner
        image={BannerCursoImg}
        descriptionWidth={362}
        title="Formação"
        description="Confira o calendário dos nossos cursos de capacitação e formação para novos agentes."
      />
      <SectionTraining />
      <SectionCalendar />
    </Container>
  );
}

export default Course;
