import React from "react";
import { useGoTop } from "@/hooks/useGoTop";
import Banner from "@/components/Banner";
import BannerThirdImg from "@/assets/images/jumbotron/Jumbotron3.jpg";
import { ReactComponent as CrossIcon } from "@/assets/icons/crossColor.svg";
import SectionStart from "./SectionStart";
import { Container, Wrapper, Mystery, Prayer, Separator } from "./styles";

function Third() {
  useGoTop();
  return (
    <Container>
      <Banner
        image={BannerThirdImg}
        title="Terço da Sobriedade"
        description="Reze o Terço da Sobriedade e peça ajuda ao Pai e à mãe divina, Maria,
pela nossa sobriedade e a de nossos amigos e familiares. "
        descriptionWidth={530}
      />
      <Wrapper>
        <SectionStart />

        <Mystery>
          <h1>1º Mistério:</h1>
          <p>(Contemplar os Mistérios de acordo com o dia da semana)</p>
          <p>Oração: Pai Nosso</p>
          <p>
            Nesse Mistério vamos pedir a Nossa Senhora que interceda pela
            recuperação dos dependentes, rezando 10 ave-marias: “Ave Maria,
            cheia de Graça, o Senhor é convosco, bendita sois vós entre as
            mulheres e bendito é o fruto do nosso ventre Jesus. Santa Maria mãe
            de Deus, rogai pela recuperação dos Dependentes Químicos, agora e
            sempre. Amém.”
          </p>
          <p>Oração: Glória ao Pai</p>
        </Mystery>

        <Prayer>
          <CrossIcon />
          <p>ORAÇÃO DE GLÓRIA AO PAI</p>
          <h1>"Glória ao Pai, ao Filho e ao Espírito Santo, Amém"</h1>
        </Prayer>

        <Mystery>
          <h1>2º Mistério:</h1>
          <p>(Contemplar os Mistérios de acordo com o dia da semana)</p>
          <p>Oração: Pai Nosso</p>
          <p>
            Nesse Mistério vamos pedir a Nossa Senhora que interceda pelos
            co-dependentes e familiares rezando 10 ave-marias: “Ave Maria, cheia
            de graça, o Senhor é convosco, bendita sois vós entre as mulheres e
            bendito é o fruto do vosso ventre, Jesus. Santa Maria mãe de Deus,
            rogai pelos co-dependentes e familiares para que participem do
            processo de libertação, agora e sempre. Amém.”
          </p>
          <p>Oração: Glória ao Pai</p>
        </Mystery>

        <Mystery>
          <h1>3º Mistério:</h1>
          <p>(Contemplar os Mistérios de acordo com o dia da semana)</p>
          <p>Oração: Pai Nosso</p>
          <p>
            Nesse Mistério vamos pedir a Nossa Senhora que interceda pelas
            Comunidades Terapêuticas Parceiras, rezando 10 ave-marias: “Ave
            Maria, cheia de graça, o Senhor é convosco, bendita sois vós entre
            as mulheres e bendito é o fruto do vosso ventre, Jesus. Santa Maria
            Mãe de Deus, rogai pelas Comunidades Terapêuticas Parceiras para que
            aceitem o projeto de Jesus Cristo libertador, agora e sempre. Amém.”
          </p>
          <p>Oração: Glória ao Pai</p>
        </Mystery>

        <Mystery>
          <h1>4º Mistério:</h1>
          <p>(Contemplar os Mistérios de acordo com o dia da semana)</p>
          <p>Oração: Pai Nosso</p>
          <p>
            Nesse Mistério vamos pedir a Nossa Senhora que interceda pelos
            agentes da Pastoral da Sobriedade, rezando 10 ave-marias: “Ave
            Maria, cheia de graça, o Senhor é convosco, bendita sois vós entre
            as mulheres e bendito é o fruto do vosso ventre, Jesus. Santa Maria
            Mãe de Deus, rogai pelos agentes da Pastoral da Sobriedade para que
            sejam sóbrios e perseverantes, agora e sempre. Amém.”
          </p>
          <p>Oração: Glória ao Pai</p>
        </Mystery>

        <Mystery>
          <h1>5º Mistério:</h1>
          <p>(Contemplar os Mistérios de acordo com o dia da semana)</p>
          <p>Oração: Pai Nosso</p>
          <p>
            Nesse Mistério vamos pedir a Nossa Senhora que interceda pela
            Igreja, para que defenda a vida com a implantação da Pastoral da
            Sobriedade, rezando 10 ave-marias: “Ave Maria, cheia de graça, o
            Senhor é convosco, e bendita sois vós entre as mulheres e bendito é
            o fruto do vosso ventre, Jesus. Santa Maria Mãe de Deus, rogai pela
            Igreja, para que defenda a vida com a implantação da Pastoral da
            Sobriedade, agora e sempre. Amém.”
          </p>
          <p>Agradecimento</p>
          <p>Oração: Salve Rainha</p>
        </Mystery>

        <Prayer>
          <CrossIcon />
          <p>SAUDAÇÕES</p>
          <h1>"Sobriedade e Paz; só por hoje, graças a Deus."</h1>
          <Separator />
          <h1>"Piedade Redentora de Cristo, Dai-nos a Sobriedade."</h1>
        </Prayer>
      </Wrapper>
    </Container>
  );
}

export default Third;
