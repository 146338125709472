import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SelectRegionalsActions } from "@/store/ducks/regionals";
import { SelectCitiesActions } from "@/store/ducks/cities";
import { SelectDiocesesActions } from "@/store/ducks/dioceses";
import { SelectParoquiasActions } from "@/store/ducks/paroquias";
import Select from "@/components/Form/Select";

import { statesOptions } from "@/utils/data";
import * as S from "./styles";

const levels = [
  {
    label: "Nível nacional",
    value: "nacional",
  },
  {
    label: "Nível regional",
    value: "regional",
  },
  {
    label: "Nível de estado",
    value: "estado",
  },
  {
    label: "Nível de cidade",
    value: "cidade",
  },
  {
    label: "Nível de diocese",
    value: "diocese",
  },
  {
    label: "Nível de paróquia",
    value: "paroquia",
  },
];

const startingYear = new Date().getFullYear();
const endingYear = startingYear - 20;

const periods = [{ label: "Histórico", value: null }].concat(
  [...Array(startingYear - endingYear + 1).keys()].map((index) => ({
    label: startingYear - index,
    value: startingYear - index,
  }))
);

const Filters = ({ onChange }) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const { data: regionals } = useSelector((state) => state.selectRegionals);
  const { data: cities } = useSelector((state) => state.selectCities);
  const { data: dioceses } = useSelector((state) => state.selectDioceses);
  const { data: paroquias } = useSelector((state) => state.selectParoquias);

  const [query, setQuery] = useState({
    level: "nacional",
  });

  const handlePeriodChange = useCallback((option) => {
    return setQuery((state) => ({
      ...state,
      year: option?.value || undefined,
    }));
  });

  const handleLevelChange = useCallback(
    (option) => {
      formRef.current.setFieldValue("uf", undefined);
      formRef.current.setFieldValue("regional_id", undefined);
      formRef.current.setFieldValue("city_id", undefined);
      formRef.current.setFieldValue("diocese_id", undefined);
      formRef.current.setFieldValue("paroquia_id", undefined);
      return setQuery((state) => ({
        ...state,
        level: option?.value || undefined,
        regional_id: undefined,
        uf: undefined,
        city_id: undefined,
        diocese_id: undefined,
        paroquia_id: undefined,
      }));
    },
    [formRef]
  );

  const handleRegionalChange = useCallback(
    (option) => {
      return setQuery((state) => ({
        ...state,
        regional_id: option?.value || undefined,
        city_id: undefined,
        diocese_id: undefined,
        paroquia_id: undefined,
      }));
    },
    [formRef]
  );

  const handleUfChange = useCallback(
    (option) => {
      formRef.current.setFieldValue("city_id", undefined);
      formRef.current.setFieldValue("diocese_id", undefined);
      formRef.current.setFieldValue("paroquia_id", undefined);
      return setQuery((state) => ({
        ...state,
        uf: option?.value || undefined,
        city_id: undefined,
        diocese_id: undefined,
        paroquia_id: undefined,
      }));
    },
    [formRef]
  );

  const handleCityChange = useCallback(
    (option) => {
      formRef.current.setFieldValue("diocese_id", undefined);
      formRef.current.setFieldValue("paroquia_id", undefined);
      return setQuery((state) => ({
        ...state,
        city_id: option?.value || undefined,
        diocese_id: undefined,
        paroquia_id: undefined,
      }));
    },
    [formRef]
  );

  const handleDioceseChange = useCallback(
    (option) => {
      formRef.current.setFieldValue("paroquia_id", undefined);
      return setQuery((state) => ({
        ...state,
        diocese_id: option?.value || undefined,
        paroquia_id: undefined,
      }));
    },
    [formRef]
  );

  const handleParoquiaChange = useCallback(
    (option) => {
      return setQuery((state) => ({
        ...state,
        paroquia_id: option?.value || undefined,
      }));
    },
    [formRef]
  );

  const fetchRegionals = useCallback(() => {
    dispatch(SelectRegionalsActions.request());
  }, [dispatch]);

  const fetchCities = useCallback(() => {
    const { uf } = query;
    dispatch(SelectCitiesActions.request({ uf }));
  }, [dispatch, query?.uf]);

  const fetchDioceses = useCallback(() => {
    const { uf, city_id, regional_id } = query;
    dispatch(SelectDiocesesActions.request({ uf, regional_id, city_id }));
  }, [dispatch, query?.uf, query?.city_id, query?.regional_id]);

  const fetchParoquias = useCallback(() => {
    const { uf, city_id, regional_id, diocese_id } = query;
    dispatch(
      SelectParoquiasActions.request({ uf, regional_id, city_id, diocese_id })
    );
  }, [
    dispatch,
    query?.uf,
    query?.city_id,
    query?.regional_id,
    query?.diocese_id,
  ]);

  useEffect(() => {
    fetchRegionals();
  }, [fetchRegionals]);

  useEffect(() => {
    fetchCities();
  }, [fetchCities]);

  useEffect(() => {
    fetchDioceses();
  }, [fetchDioceses]);

  useEffect(() => {
    fetchParoquias();
  }, [fetchParoquias]);

  useEffect(() => {
    onChange && onChange(query);
  }, [query, onChange]);

  return (
    <S.MainContainer>
      <S.Form ref={formRef}>
        <Select
          name="year"
          label="Período:"
          options={periods}
          value={periods[0]}
          onChange={handlePeriodChange}
          placeholder="Período"
        />
        <Select
          name="level"
          label="Nível de detalhe:"
          options={levels}
          onChange={handleLevelChange}
          value={levels[0]}
          placeholder="Nível de detalhe"
        />
        {query.level === "regional" && (
          <Select
            name="regional_id"
            label="Regional:"
            options={regionals}
            onChange={handleRegionalChange}
            isClearable={true}
            placeholder="Regional"
          />
        )}
        {["estado", "cidade", "diocese", "paroquia"].includes(query.level) && (
          <Select
            name="uf"
            label="Estado:"
            options={statesOptions}
            onChange={handleUfChange}
            isClearable={true}
            placeholder="Estado"
          />
        )}
        {["cidade", "diocese", "paroquia"].includes(query.level) && (
          <Select
            name="city_id"
            label="Cidade:"
            options={cities}
            onChange={handleCityChange}
            isClearable={true}
            placeholder="Cidade"
          />
        )}
        {["diocese", "paroquia"].includes(query.level) && (
          <Select
            name="diocese_id"
            label="Diocese:"
            options={dioceses}
            onChange={handleDioceseChange}
            isClearable={true}
            placeholder="Diocese"
          />
        )}
        {query.level === "paroquia" && (
          <Select
            name="paroquia_id"
            label="Paróquia:"
            options={paroquias}
            onChange={handleParoquiaChange}
            isClearable={true}
            placeholder="Paróquia"
          />
        )}
      </S.Form>
    </S.MainContainer>
  );
};

export default Filters;
