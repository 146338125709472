import React from "react";
import { Container, Title, Body } from "@/styles/components/TextColumn";
import { Button } from "./styles";
function SectionIdentity() {
  return (
    <Container>
      <Title color="red">
        A identidade da Pastoral da Sobriedade é o
        <strong> Grupo de Autoajuda</strong>.
      </Title>

      <Body>
        <div>
          <p>
            O pároco ou o responsável pela comunidade eclesial, por recomendação
            do Conselho Pastoral, por iniciativa própria ou por sugestão de
            algum leigo convida um grupo de pessoas para trabalhar a favor da
            vida, a fim de formar na paróquia uma equipe interessada na
            implantação do Grupo de Autoajuda da Pastoral da Sobriedade,
            priorizando novas lideranças.
          </p>
          <p>
            Encaminha os componentes da equipe à participação no Curso Formação
            de Capacitação da Pastoral da Sobriedade.
          </p>
        </div>

        <div>
          <p>
            Os agentes articulam, em reunião com o Pároco sobre o local, dia e
            horário das reuniões do Grupo de Autoajuda da Pastoral da
            Sobriedade.
          </p>
          <p>
            Lavram Ata dessa reunião e enviam cópia da Ata de Abertura do Grupo
            de Autoajuda assinada pelo Pároco para a Coordenação
            Diocesana/Regional/Nacional, para efeito de Registro.
          </p>
        </div>
      </Body>
    </Container>
  );
}

export default SectionIdentity;
