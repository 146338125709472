import styled from "styled-components";
import { mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 340px;
  height: 440px;
  background: ${colors.white};
  padding: 40px 34px;

  ${mixinRespondTo.md`
     height: 380px;
  `}
`;
export const State = styled.p`
  flex: 1;
  font-family: "CircularMedium", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  color: ${colors.blue5};
`;
export const City = styled.p`
  flex: 1;
  font-family: "CircularBold", sans-serif;
  font-size: 2.6rem;
  font-weight: bold;
  text-transform: capitalize;
  text-align: left;
  color: ${colors.blue2};
`;
export const Title = styled.h1`
  flex: 1;
  font-family: "Roboto", sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: capitalize;
  text-align: left;
  color: ${colors.blue3};
`;
export const Description = styled.p`
  flex: 1;
  opacity: 0.9;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  text-align: left;
`;
export const Date = styled.p`
  flex: 2;
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  color: ${colors.blue10};
  & > svg {
    color: ${colors.red1};
    margin-right: 12px;
  }
`;
export const Info = styled.p`
  opacity: 0.9;
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  color: ${colors.gray3};
`;
