import styled from "styled-components";
import { Link } from "react-router-dom";
import { mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";
export const Container = styled.div``;
export const Card = styled.div`
  width: 100%;
  max-width: 340px;
  height: 477px;
  background-color: ${colors.gray2};
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    transform: scale(1.06);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  ${mixinRespondTo.md`
    height: 400px;
  `}
`;

export const CardImg = styled.img`
  width: 100%;
`;

export const CardBody = styled.div`
  padding: 30px 34px;
`;

export const CardTag = styled.span`
  font-family: "CircularMedium", sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  color: ${colors.blue3};
`;

export const CardTitle = styled.h1`
  font-family: "CircularBold", sans-serif;
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  color: ${colors.blue2};
  margin: 18px 0 24px;
`;

export const CardDesc = styled.p`
  opacity: 0.9;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  text-align: left;
  line-height: 1.5;
  color: ${colors.gray3};
`;
