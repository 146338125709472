import React from "react";

import { Container, BannerWrapper, BannerInfo } from "./styles";

function Banner({ image, title, description, titleWidth, descriptionWidth }) {
  return (
    <Container src={image}>
      <BannerWrapper>
        <BannerInfo titleWidth={titleWidth} descriptionWidth={descriptionWidth}>
          <h1>{title}</h1>
          <p>{description}</p>
        </BannerInfo>
      </BannerWrapper>
    </Container>
  );
}

export default Banner;
