import { createGlobalStyle } from "styled-components";
import { mixinRespondTo } from "./tools";
import { colors } from "./variables";
import CircularMedium from "../assets/fonts/CircularStd-Medium.ttf";
import CircularBlack from "../assets/fonts/CircularStd-Black.ttf";
import CircularBold from "../assets/fonts/CircularStd-Bold.ttf";
import CircularBook from "../assets/fonts/CircularStd-Book.ttf";

import "../assets/fonts/Roboto-Light.ttf";
import "../assets/fonts/Roboto-Medium.ttf";
import "../assets/fonts/Roboto-Regular.ttf";
import "../assets/fonts/Roboto-Bold.ttf";
import "../assets/fonts/Roboto-Black.ttf";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "CircularMedium";
  font-weight: 500;
  src: url(${CircularMedium});
}
@font-face {
  font-family: "CircularBold";
  font-weight: 700;
  src: url(${CircularBold});
}
@font-face {
  font-family: "CircularBlack";
  font-weight: 900;
  src: url(${CircularBlack});
}

@font-face {
  font-family: "CircularBook";
  font-weight: 500;
  src: url(${CircularBook});
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html {
  font-size: 62.5%;
}

html,
body,
#root {
  width: 100%;
  height: 100vh;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  font-family: 'Roboto', sans-serif;
}
body {
  background: ${colors.white};
  color: ${colors.black};
  font-size: 1.6rem;

  .fade-appear,
  .fade-enter {
      opacity: 0.9;
      z-index: 1;
  }
  .fade-appear-active,
  .fade-enter.fade-enter-active {
      opacity: 1;
      transition: opacity 300ms linear 150ms;
  }

  .fade-exit {
      opacity: 1;
  }

  .fade-exit.fade-exit-active {
      opacity: 0.9;
      transition: opacity 150ms linear;
  }

.swiper-wrapper {
    height: 100%;
    margin: 12px auto;
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100% !important;
    left: auto !important;
    margin: 0;
    z-index: 2;
  }
  .swiper-pagination-bullet {
    border-radius: 9px;
    width: 9px;
    height: 9px;
    opacity: 0.5;
    background-color:${colors.gray12};
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
    background-color: ${colors.blue5};
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: "CircularMedium", sans-serif;
}

button {
  font-family: "CircularMedium", sans-serif;
  border: 0;
  cursor: pointer;
}
input {
  font-family: 'Roboto', sans-serif;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  cursor: pointer;
}

${mixinRespondTo.md`
 html {
    font-size: 50%;
  }
`}

`;

export default GlobalStyle;
