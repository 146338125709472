import React from "react";
import { useGoTop } from "@/hooks/useGoTop";
import Jumbotron from "@/components/Jumbotron";
import Banner from "@/components/Banner";
import JumbotronCenter from "@/components/JumbotronCenter";
import { Button } from "@/styles/components/Button";
import { ReactComponent as CrossIcon } from "@/assets/icons/cross.svg";
import { ReactComponent as QuotesIcon } from "@/assets/icons/quotes.svg";
import BannerAboutImg from "@/assets/images/banner/BannerAbout.jpg";
import Jumbotron2Img from "@/assets/images/jumbotron/Jumbotron2.jpg";
import Jumbotron3Img from "@/assets/images/jumbotron/Jumbotron3.jpg";
import {
  Container,
  SectionAction,
  SectionActionTitle,
  SectionActionDesc,
  SectionChallenge,
  SectionChallengeTitle,
  SectionChallengeText,
  SectionChallengeMessage,
  SectionChallengeQuote,
} from "./styles";

function About() {
  useGoTop();

  return (
    <Container>
      <Banner
        image={BannerAboutImg}
        title="Sobre a Pastoral"
        description="A Pastoral da Sobriedade é a ação concreta da Igreja na
Prevenção e Recuperação da Dependência Química."
      />

      <SectionAction>
        <SectionActionTitle>
          Atuação especial da Igreja, diante de um problema da
          <strong> sociedade</strong>
        </SectionActionTitle>
        <SectionActionDesc>
          <p>
            Considerando que 25% da população brasileira está, direta ou
            indiretamente, ligada ao fenômeno das drogas, que cada vez mais cedo
            os adolescentes entram em contato com as drogas, carregando consigo,
            em média, quatro outras pessoas, chamadas de co-dependentes, membros
            da família e amigos, a Pastoral da Sobriedade capacita aqueles, que
            de alguma maneira, se identificam com a causa e desejam lutar pela
            vida, tornando-se um Agente da Pastoral da Sobriedade.
          </p>
          <p>
            Somos uma ação pastoral conjunta que busca a integração entre todas
            as Pastorais, Movimentos, Comunidades Terapêuticas Parceiras, Casas
            de Recuperação para, através da pedagogia de Jesus-Libertador,
            resgatar e reinserir os excluídos, propondo uma mudança de vida
            através da conversão.
          </p>
        </SectionActionDesc>
      </SectionAction>

      <JumbotronCenter image={Jumbotron3Img}>
        <CrossIcon />
        <h1>
          A exemplo de seu Senhor, a Igreja vem em socorro dos menores e dos
          mais fracos
        </h1>
        <p>
          Cuidando daqueles que estão feridos, fortalecendo aqueles que estão
          doentes, reforçando a promoção pessoal de cada um.
        </p>
      </JumbotronCenter>

      <SectionChallenge>
        <SectionChallengeTitle>Nosso Desafio</SectionChallengeTitle>

        <SectionChallengeText>
          <p>
            "Frente a esse flagelo da droga que assola direta ou indiretamente
            quase 100 milhões de brasileiros, que há muito tempo vem
            escravizando tirando a dignidade e a consciência de muitos
            dependentes e desestruturando seus familiares "co-dependentes", nós
            como Igreja, nos omitimos.
          </p>
          <p>
            Sabemos que no Brasil já existem muitos trabalhos de Prevenção e
            Recuperação em dependência química, agradecemos a Deus por eles!
          </p>
          <p>
            Agora porém, pela graça de Deus e inspirados pelo Espírito Santo,
            Dom Irineu Danelon e os Bispos do Brasil, na 36ª Assembléia, em
            Itaici/98, profeticamente e com a certeza absoluta de defender a
            vida – "Vida plena e em abundância" Jo 10,10 – a proposta de Jesus
            Cristo – corajosamente aprovaram a criação da Pastoral da Sobriedade
            para que em nossa Igreja tenhamos uma ação concreta e organizada com
            5 frentes de atuação: Prevenção, Intervenção, Reinserção familiar e
            social e Atuação Política.
          </p>
        </SectionChallengeText>

        <SectionChallengeMessage>
          <QuotesIcon />
          <h1>
            Como Igreja, viemos para somar com todas as iniciativas já
            existentes nesta área.
          </h1>
        </SectionChallengeMessage>

        <SectionChallengeText>
          <p>
            E para assumirmos nosso compromisso na prevenção e na recuperação
            dos dependentes químicos e de seus familiares, com o Grupo de
            Autoajuda, através de reuniões semanais vivenciando os 12 Passos da
            Pastoral da Sobriedade, fundamentados e baseados no Evangelho e na
            doutrina da Igreja. Como Igreja, viemos para somar com todas as
            iniciativas já existentes nesta área. Queremos trabalhar em
            conjunto, principalmente na Prevenção e Atuação Política.
          </p>
          <p>
            Prevenir é a nossa prioridade. E a eficácia da Prevenção vai
            depender da boa articulação política de cada Diocese, Paróquia com
            todas as forças vivas da sociedade, forças essas que juntas querem
            promover a Vida.
          </p>
          <p>
            Devemos e precisamos trabalhar em parceria com tudo e com todos que
            são a favor do resgate da dignidade, da auto-estima de cada
            dependente químico e de seus familiares. Como Igreja, a exemplo de
            nosso Mestre Jesus, a Pastoral da Sobriedade, unindo-se a todas as
            Pastorais Sociais e Movimentos comprometidos com o Projeto de Jesus
            Cristo-Libertador, queremos fazer dos excluídos os nossos
            preferidos.
          </p>
          <p>
            Se somos cristãos e podemos ajudar a libertar um dependente químico,
            é de fundamental importância que este conheça o verdadeiro
            libertador – Jesus Cristo – e não ser apenas um Ser Superior,
            evangelizando-o, ou melhor dizendo, revangelizando-o junto com seus
            familiares para que todos se ajudem mutuamente, pois onde há um
            dependente químico na família, todos patologicamente contribuem,
            consciente ou inconsciente para esse mal. Por isso a Pastoral da
            Sobriedade trabalha não só o dependente químico mas quer atingir
            também os co-dependentes, os familiares. O trabalho da Pastoral da
            Sobriedade acontece de forma sistêmica, envolve o dependente
            químico, seus familiares e amigos, engajando-os na comunidade a qual
            pertencem.Nós responsáveis pela Formação, Capacitação, Treinamento e
            Implantação dos Grupos de Autoajuda da Pastoral da Sobriedade nas
            Dioceses, Paróquias e Comunidades trabalhamos dentro das Diretrizes
            de Evangelização da CNBB.
          </p>
          <p>
            A Pastoral da Sobriedade é uma atuação especial em resposta a um
            problema social, dirigida pelos pastores e em comunhão com toda a
            doutrina da Igreja. O único pastor da Igreja. O único pastor é Jesus
            Cristo. Nós todos somos participantes desse pastoreio.
          </p>
          <p>
            O agente da Pastoral da Sobriedade deve estar aberto ao diálogo,
            disponível para o serviço e em sobriedade. Ser testemunha viva em
            sua comunidade, participar de todo o projeto paroquial e jamais agir
            isoladamente, tendo presente que o primeiro motivador e coordenador
            na paróquia é o padre, junto com o CPP.
          </p>
          <p>
            Perseverando na comunhão queremos viver em Cristo, promovendo a vida
            e a união fraterna entre os irmãos; partilhando, na vivência
            solidária, tudo o que temos, sabemos e somos. A você que é ou vai se
            tornar um Agente da Pastoral da Sobriedade e também a todos aqueles
            que já estão no Projeto de Jesus, Este um dia lhe dirá:
          </p>
        </SectionChallengeText>

        <SectionChallengeQuote>
          "Vinde benditos de meu Pai, tomai posse do Reino que vos está
          preparado desde antes da criação do mundo; porque tive fome e me
          destes de comer, tive sede e me destes de beber, era peregrino e me
          acolhestes, estava nu e me vestistes, enfermo e me visitastes, estava
          na prisão ... era dependente químico ... e viestes a mim".
        </SectionChallengeQuote>
      </SectionChallenge>

      <Jumbotron image={Jumbotron2Img}>
        <h1>Curso de formação para novos agentes</h1>
        <p>
          Você que quer se dedicar à Pastoral pode fazer um dos nossos cursos
          ministrados pela CNBB - Coordenação Nacional da Pastoral da
          Sobriedade. E desenvolver-se em tópicos como a espiritualidade da
          Pastoral, a estrutura da Reunião de Autoajuda, características do
          agente, entre outros conteúdos importantes para a sua formação.
        </p>

        <Button to="/sobre/curso-formacao">Veja o calendário do curso</Button>
      </Jumbotron>
    </Container>
  );
}

export default About;
