import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { RequestErrorHandler } from "utils";

import { NewsActions } from "store/ducks/news";

export function* fetchNewsRequest(action) {
  try {
    const { id } = action;
    const { data } = yield call(api.get, `open/news/${id}`);
    yield put(NewsActions.success(data));
  } catch (error) {
    const { errorMessage } = RequestErrorHandler(error);
    notify("error", errorMessage);
    yield put(NewsActions.failure(errorMessage));
  }
}
