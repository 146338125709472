import { all, takeLatest } from "redux-saga/effects";

// Duck Types
import { ListCalendarsTypes } from "store/ducks/calendar";
import { CepTypes } from "store/ducks/cep";
import { SelectCitiesTypes } from "store/ducks/cities";
import { CourseTypes, ListCoursesTypes } from "store/ducks/courses";
import { SelectDiocesesTypes } from "store/ducks/dioceses";
import { EventTypes, ListEventsTypes } from "store/ducks/events";
import { CreateFnctcTypes, ListFnctcsTypes } from "store/ducks/fnctcs";
import { ListGroupsTypes } from "store/ducks/groups";
import { NewsTypes, ListNewsTypes } from "store/ducks/news";
import { SelectParoquiasTypes } from "store/ducks/paroquias";
import { SelectRegionalsTypes } from "store/ducks/regionals";
import { StatisticsTypes, LegacyStatisticsTypes } from "store/ducks/statistics";

// Sagas
import { listCalendarsRequest } from "./calendar";
import { cepRequest } from "./cep";
import { selectCitiesRequest } from "./cities";
import { fetchCourseRequest, listCoursesRequest } from "./courses";
import { selectDiocesesRequest } from "./dioceses";
import { fetchEventRequest, listEventsRequest } from "./events";
import { createFnctcRequest, listFnctcsRequest } from "./fnctcs";
import { listGroupsRequest } from "./groups";
import { fetchNewsRequest, listNewsRequest } from "./news";
import { selectParoquiasRequest } from "./paroquias";
import { selectRegionalsRequest } from "./regionals";
import {
  fetchStatisticsRequest,
  fetchLegacyStatisticsRequest,
} from "./statistics";

export default function* rootSaga() {
  yield all([
    // calendars
    takeLatest(ListCalendarsTypes.REQUEST, listCalendarsRequest),

    // cep
    takeLatest(CepTypes.REQUEST, cepRequest),

    // cities
    takeLatest(SelectCitiesTypes.REQUEST, selectCitiesRequest),

    // courses
    takeLatest(CourseTypes.REQUEST, fetchCourseRequest),
    takeLatest(ListCoursesTypes.REQUEST, listCoursesRequest),

    // dioceses
    takeLatest(SelectDiocesesTypes.REQUEST, selectDiocesesRequest),

    // events
    takeLatest(EventTypes.REQUEST, fetchEventRequest),
    takeLatest(ListEventsTypes.REQUEST, listEventsRequest),

    // fnctcs
    takeLatest(CreateFnctcTypes.REQUEST, createFnctcRequest),
    takeLatest(ListFnctcsTypes.REQUEST, listFnctcsRequest),

    // groups
    takeLatest(ListGroupsTypes.REQUEST, listGroupsRequest),

    // news
    takeLatest(NewsTypes.REQUEST, fetchNewsRequest),
    takeLatest(ListNewsTypes.REQUEST, listNewsRequest),

    // paroquias
    takeLatest(SelectParoquiasTypes.REQUEST, selectParoquiasRequest),

    // regionals
    takeLatest(SelectRegionalsTypes.REQUEST, selectRegionalsRequest),

    // statistics
    takeLatest(StatisticsTypes.REQUEST, fetchStatisticsRequest),
    takeLatest(LegacyStatisticsTypes.REQUEST, fetchLegacyStatisticsRequest),
  ]);
}
