import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0 24px;
  border-top: 1px solid #e5e5e5;
  margin-top: 48px;
  padding-top: 24px;

  @media (max-width: 840px) {
    flex-direction: column;
    gap: 48px 0;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LegendHeader = styled.div`
  display: grid;
  grid-template-columns: 120px auto;
  gap: 12px;
  margin-bottom: 24px;
  font-size: 16px;
`;

export const LegendTitle = styled.div``;

export const LegendItem = styled.div`
  display: grid;
  grid-template-columns: 120px 10px auto;
  align-items: center;
  gap: 12px;
  padding: 4px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
`;

export const LegendPercentage = styled.div``;

export const LegendBubble = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

export const LegendLabel = styled.div``;
