import React from "react";

import { cep, telefone } from "utils/formatters";

import { Container, Title, Contact, Meeting, Item } from "./styles";

function GroupCard({ group }) {
  const {
    city,
    address_street,
    address_number,
    address_neighborhood,
    zipcode,
    weekday,
    time,
    paroquial_coordinator,
    paroquia,
  } = group;

  let address = [address_street, address_number, address_neighborhood].join(
    ",  "
  );
  address = `${address}, CEP: ${cep(zipcode)}, ${city.name} - ${city.uf}`;

  return (
    <Container>
      <Title>Paróquia {paroquia.name}</Title>
      <Contact>
        <p>
          <strong>Endereço:</strong> {address}
        </p>
      </Contact>
      <Meeting>
        <strong>Reuniões: </strong>
        {weekday}s às {time.substring(0, 5)}h
      </Meeting>
      <Item>
        <strong>Coordenador Paroquial:</strong>
        {paroquial_coordinator.name}
      </Item>
    </Container>
  );
}

export default GroupCard;
