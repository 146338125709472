import styled from "styled-components";
import { mixinWrapper } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div``;
export const Wrapper = styled.div`
  ${mixinWrapper};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 72px 0;
`;

export const FormWrapper = styled.div`
  h1 {
    font-family: "CircularMedium";
    font-size: 2.6rem;
    font-weight: 500;
    text-align: center;
    color: ${colors.blue3};
  }

  hr {
    width: 100%;
    height: 2px;
    color: ${colors.gray8};
    margin: 25px 0 50px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 32px 0;

    & > div {
      width: 80vw;
      max-width: 720px;
    }

    button {
      width: 262px;
      height: 60px;
      border-radius: 4px;
      background-color: ${colors.blue3};
      font-family: "CircularBold";
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      color: ${colors.white};
    }
  }
`;
export const Title = styled.h1`
  font-family: "CircularMedium", sans-serif;
  font-size: 3.8rem;
  font-weight: 500;
  text-align: center;
  color: ${colors.blue3};
  max-width: 280px;
`;
export const SubTitle = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  text-align: center;
  color: ${colors.gray3};
  max-width: 564px;
  margin: 24px 0 48px 0;
`;
