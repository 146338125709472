import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

const { Types, Creators } = createActions(
  {
    request: ["query"],
    success: ["data"],
    failure: ["errorMessage"],
    reset: [],
  },
  { prefix: "SELECT_PAROQUIAS_" }
);

const INITIAL_STATE = {
  data: [],
  loading: false,
  errorMessage: null,
};

const request = (state) =>
  update(state, {
    loading: { $set: true },
    errorMessage: { $set: null },
  });

const success = (state, action) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
  });

const failure = (state, action) =>
  update(state, {
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  });

const reset = () => INITIAL_STATE;

export const selectParoquias = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const SelectParoquiasTypes = Types;
export const SelectParoquiasActions = Creators;
