import styled from "styled-components";
import { mixinRespondTo } from "@/styles/tools";
export const Row = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;

  ${mixinRespondTo.md`
    display: block;
  `};
`;
