import styled from "styled-components";
import { Form as FormUnform } from "@unform/web";

export const MainContainer = styled.div``;

export const Form = styled(FormUnform)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px 42px;
  margin-bottom: 48px;

  @media (min-width: 769px) {
    > *:nth-child(2):nth-last-of-type(1) {
      grid-column: span 2;
    }

    > *:nth-child(4):nth-last-of-type(1) {
      grid-column: span 3;
    }

    > *:nth-child(5):nth-last-of-type(1) {
      grid-column: span 2;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    > *:nth-child(1):nth-last-of-type(1),
    > *:nth-child(3):nth-last-of-type(1),
    > *:nth-child(5):nth-last-of-type(1) {
      grid-column: span 2;
    }
  }

  div.field-container {
    margin: unset;
  }
`;
