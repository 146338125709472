export const cnpj = (document) => {
  if (!document) return "";
  return document.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );
};

export const cpf = (document) => {
  if (!document) return "";
  return document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export const telefone = (value) => {
  if (!value) return "";
  if (value.length <= 10) {
    return value.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  }
  if (value.length >= 11) {
    // para o formato (21) 98989-9898
    return value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  }
};

export const cep = (value) => {
  if (!value) return "";
  return value.replace(/^(\d{5})(\d{3})/, "$1-$2");
};
