import React from "react";
import { telefone } from "utils/formatters";
import { Container, Title, SubTitle, Text } from "./styles";

function CommunityCard({ community }) {
  const { razao_social, nome_fantasia, city, phone } = community;

  return (
    <Container>
      <Title>{nome_fantasia}</Title>
      <SubTitle>
        {city.name}, {city.uf}
      </SubTitle>
      <Text>
        <strong>Razão Social:</strong>
        {razao_social}
      </Text>
      {phone && (
        <Text>
          <strong>Telefone:</strong>
          {telefone(phone)}
        </Text>
      )}
    </Container>
  );
}

export default CommunityCard;
