import React from "react";
import { Separator } from "@/styles/components/Separator";
import { Container, Title, Description } from "./styles";

function SectionTraining() {
  return (
    <Container>
      <Separator />
      <Title>
        Curso de capacitação de <strong>agentes</strong>
      </Title>
      <Description>
        Você que quer se dedicar à Pastoral pode fazer um dos nossos cursos
        ministrados pela CNBB - Coordenação Nacional da Pastoral da Sobriedade.
        E desenvolver-se em tópicos como a espiritualidade da Pastoral, a
        estrutura da Reunião de Autoajuda, características do agente, entre
        outros conteúdos importantes para a sua formação.
      </Description>
    </Container>
  );
}

export default SectionTraining;
