import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swiper from "react-id-swiper";

import { ListNewsActions } from "store/ducks/news";
import NewsCard from "@/components/NewsCard";
import Loading from "@/components/Loading";

import { Container, Title, CardGroup, CarouselGroup, Slide } from "./styles";

function SectionNews({ title, query = {} }) {
  const { data: listNewsData, loading: listNewsLoading } = useSelector(
    (state) => state.listNews
  );
  const dispatch = useDispatch();

  const params = {
    slidesPerView: 1,
    spaceBetween: 20,
    shouldSwiperUpdate: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  };

  const fecthNewsCB = useCallback(() => {
    dispatch(ListNewsActions.request({ publishedOnly: 1, limit: 3, ...query }));
  }, [dispatch]);

  useEffect(() => {
    fecthNewsCB();
  }, [fecthNewsCB]);

  useEffect(() => {
    return () => {
      dispatch(ListNewsActions.reset());
    };
  }, [dispatch]);

  function renderNews() {
    if (listNewsLoading) return <Loading />;

    return (
      <>
        <CardGroup>
          {listNewsData.map(
            (item, index) => index <= 2 && <NewsCard news={item} key={index} />
          )}
        </CardGroup>
        <CarouselGroup>
          <Swiper {...params}>
            {listNewsData.slice(0, 3).map((item, index) => (
              <Slide key={index}>
                <NewsCard news={item} />
              </Slide>
            ))}
          </Swiper>
        </CarouselGroup>
      </>
    );
  }

  return (
    <Container>
      <Title>{title}</Title>
      {renderNews()}
    </Container>
  );
}

export default SectionNews;
