import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { RequestErrorHandler } from "utils";

import { CourseActions } from "store/ducks/courses";

export function* fetchCourseRequest(action) {
  try {
    const { id } = action;
    const { data } = yield call(api.get, `open/course/${id}`);
    yield put(CourseActions.success(data));
  } catch (error) {
    const { errorMessage } = RequestErrorHandler(error);
    notify("error", errorMessage);
    yield put(CourseActions.failure(errorMessage));
  }
}
