import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";

const { Types, Creators } = createActions(
  {
    request: ["query"],
    success: ["data", "pagination"],
    failure: ["errorMessage"],
    reset: [],
  },
  { prefix: "LIST_COURSES_" }
);

export const INITIAL_STATE = {
  data: [],
  pagination: {},
  loading: false,
  errorMessage: null,
};

const request = (state) =>
  update(state, {
    loading: { $set: true },
    errorMessage: { $set: null },
  });

const success = (state, action) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
    pagination: { $set: action.pagination },
  });

const failure = (state, action) =>
  update(state, {
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  });

const reset = () => INITIAL_STATE;

export const listCourses = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.RESET]: reset,
});

export const ListCoursesTypes = Types;
export const ListCoursesActions = Creators;
