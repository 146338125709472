import { call, put } from "redux-saga/effects";
import { api, notify, queryBuilder } from "services";
import { RequestErrorHandler } from "utils";

import { LegacyStatisticsActions } from "store/ducks/statistics";

export function* fetchLegacyStatisticsRequest(action) {
  try {
    const { query = {}, onSuccess } = action;
    const queryString = queryBuilder(query);
    const { data } = yield call(api.get, `legacy-statistics?${queryString}`);

    if (onSuccess) onSuccess();

    yield put(LegacyStatisticsActions.success(data));
  } catch (error) {
    const { errorMessage } = RequestErrorHandler(error);
    notify("error", errorMessage);
    yield put(LegacyStatisticsActions.failure(errorMessage));
  }
}
