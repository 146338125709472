import React from "react";
import CardHomeImg from "@/assets/images/CardHome.jpg";
import {
  Container,
  Wrapper,
  CardImg,
  CardInfo,
  CardInfoTitle,
  CardInfoDesc,
  CardInfoButton,
} from "./styles";

function SectionActions() {
  return (
    <Container>
      <Wrapper>
        <CardImg src={CardHomeImg} />
        <CardInfo>
          <CardInfoTitle>
            Ações concretas na reabilitação de dependentes.
          </CardInfoTitle>
          <CardInfoDesc>
            Considerando que 25% da população brasileira está, direta ou
            indiretamente, ligada ao fenômeno das drogas, que cada vez mais cedo
            os adolescentes entram em contato com as drogas, a Pastoral da
            Sobriedade capacita aqueles, que de alguma maneira, se identificam
            com a causa e desejam lutar pela vida.
          </CardInfoDesc>
          <CardInfoButton to="/sobre">Conheça a Pastoral</CardInfoButton>
        </CardInfo>
      </Wrapper>
    </Container>
  );
}

export default SectionActions;
