import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { statesOptions } from "utils";
import { SelectCitiesActions } from "store/ducks/cities";
import { ListGroupsActions } from "store/ducks/groups";

import { useGoTop } from "@/hooks/useGoTop";
import BannerGroupImg from "@/assets/images/banner/BannerGrupo.jpg";
import Banner from "@/components/Banner";
import Loading from "@/components/Loading";
import Paginator from "@/components/Paginator";
import Select from "@/components/Form/Select";
import GroupCard from "./GroupCard";

import * as S from "./styles";

function Groups() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    limit: 6,
    ongoingOnly: 1,
  });
  const [selectedUf, setSelectedUf] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [cityName, setCityName] = useState(null);
  const [ufName, setUfName] = useState(null);

  const {
    data: groups,
    pagination,
    loading,
  } = useSelector((state) => state.listGroups);
  const { data: citiesOptions } = useSelector((state) => state.selectCities);

  const subtitle = useMemo(() => {
    if (ufName && cityName) {
      return `Grupos em ${cityName}, ${ufName}`;
    } else if (ufName) {
      return `Grupos em ${ufName}`;
    }
    return "Grupos próximos a você";
  }, [ufName, cityName]);

  const totalMessage = useMemo(() => {
    const total = pagination?.total || 0;
    switch (true) {
      case total === 1:
        return "Encontramos 1 grupo próximo a você.";
      case total > 1:
        return `Encontramos ${total} grupos próximos a você.`;
      default:
        return "Não encontramos grupos próximos a você.";
    }
  }, [pagination?.total]);

  useGoTop();

  const handleUfChange = useCallback(
    (option) => {
      const value = option ? option.value : null;
      const label = option ? option.label : null;
      if (value && value === selectedUf) return;
      setSelectedUf(value);
      setUfName(label);
      setQuery((state) => ({ ...state, uf: value }));
      dispatch(SelectCitiesActions.request({ uf: value }));
    },
    [selectedUf, dispatch]
  );

  const handleCityChange = useCallback(
    (option) => {
      const value = option ? option.value : null;
      const label = option ? option.label : null;
      if (value && value === selectedCity) return;
      setSelectedCity(value);
      setCityName(label);
      setQuery((state) => ({ ...state, city_id: value }));
    },
    [selectedCity]
  );

  const handlePageChange = useCallback((page) => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const GroupsComponent = useCallback(() => {
    if (loading) return <Loading />;

    return (
      <S.Result>
        <S.Subtitle>{subtitle}</S.Subtitle>
        <S.Description>{totalMessage}</S.Description>
        <S.List>
          {groups.map((g) => (
            <GroupCard group={g} key={g.id} />
          ))}
        </S.List>
        <Paginator pagination={pagination} onPageChange={handlePageChange} />
      </S.Result>
    );
  }, [loading, groups, pagination, subtitle, totalMessage, handlePageChange]);

  const fetchGroups = useCallback(() => {
    if (!query?.uf) {
      return dispatch(ListGroupsActions.reset());
    } else {
      return dispatch(ListGroupsActions.request(query));
    }
  }, [dispatch, query]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  useEffect(() => {
    return () => {
      dispatch(SelectCitiesActions.reset());
      dispatch(ListGroupsActions.reset());
    };
  }, [dispatch]);

  return (
    <S.Container>
      <Banner
        image={BannerGroupImg}
        title="Encontre um Grupo"
        description="São diversos Grupos de Autoajuda da Sobriedade esplhados pelo Brasil, encontre um próximo a você!"
        descriptionWidth={390}
      />

      <S.Wrapper>
        <S.Title>Pesquisar Grupos</S.Title>
        <S.Form ref={formRef}>
          <div>
            <label htmlFor="estado">Selecione um Estado:</label>
            <Select
              name="estado"
              id="estado"
              options={statesOptions}
              onChange={handleUfChange}
              isClearable
            />
          </div>
          <div>
            <label htmlFor="cidade">Selecione uma Cidade:</label>
            <Select
              name="cidade"
              id="cidade"
              options={citiesOptions}
              onChange={handleCityChange}
              isClearable
            />
          </div>
        </S.Form>
        <GroupsComponent />
      </S.Wrapper>
    </S.Container>
  );
}

export default Groups;
