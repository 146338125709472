import React from "react";
import { useGoTop } from "@/hooks/useGoTop";
import Banner from "@/components/Banner";
import SectionDatasheet from "./SectionDatasheet";
import SectionFree from "./SectionFree";
import Book from "./Book";
import Book1Img from "@/assets/images/Book1.jpg";
import Book2Img from "@/assets/images/Book2.jpg";
import Book3Img from "@/assets/images/Book3.jpg";
import CdPastoralImg from "@/assets/images/cd-pastoral.jpg";
import BookCaminhadaImg from "@/assets/images/consolidando-caminhada.jpg";
import TercoImg from "@/assets/images/terco.jpg";
import BannerArquivos from "@/assets/images/banner/BannerArquivos.jpg";
import Camisa1 from "@/assets/images/camisa1.jpg";
import Camisa2 from "@/assets/images/camisa2.jpg";
import Camisa3 from "@/assets/images/camisa3.jpg";
import {
  Container,
  Wrapper,
  Title,
  Description,
  BookGroup,
  RowWrapper,
} from "./styles";

function Archives() {
  const materials = [
    {
      image: Book1Img,
      title:
        "Formação e Capacitação do Agente Implantação do Grupo de Autoajuda",
      price: 20,
    },
    {
      image: Book2Img,
      title: "Programa de Vida Nova Os 12 Passos da Sobriedade",
      price: 25,
    },
    {
      image: Book3Img,
      title: "O Cantar do Programa de Vida Nova",
      price: 10,
    },
    {
      image: BookCaminhadaImg,
      title: "Consolidando a Caminhada",
      price: 10,
    },
    {
      image: CdPastoralImg,
      title: "CD da Pastoral do Sobriedade",
      price: 15,
    },
    {
      image: TercoImg,
      title: "Terço",
      price: 8,
    },
    {
      image: Camisa1,
      title: "Camiseta oficial da Pastoral da Sobriedade",
      price: 35,
    },
    {
      image: Camisa2,
      title: "Camiseta oficial da Pastoral da Sobriedade",
      price: 40,
    },
    {
      image: Camisa3,
      title: "Camiseta oficial da Pastoral da Sobriedade",
      price: 40,
    },
  ];
  useGoTop();
  return (
    <Container>
      <Banner
        image={BannerArquivos}
        titleWidth={360}
        descriptionWidth={320}
        title="Arquivos e Materiais"
        description="Conteúdos de reflexão e espiritualidade da Pastoral da Sobriedade"
      />
      <Wrapper>
        <Title>Materiais sob encomenda</Title>
        <Description>
          Encomende seu material pelo e-mail:
          <a href="mailto:cnbb3@sobriedade.org.br"> cnbb3@sobriedade.org.br</a>
        </Description>

        <BookGroup>
          {materials.map((m) => (
            <Book image={m.image} title={m.title} price={m.price} />
          ))}
        </BookGroup>

        <RowWrapper>
          <SectionDatasheet />
          <SectionFree />
        </RowWrapper>
      </Wrapper>
    </Container>
  );
}

export default Archives;
