import styled from "styled-components";
import { mixinWrapper, mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";
export const Container = styled.div``;
export const SectionAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 466px;
  padding: 72px 0;

  ${mixinRespondTo.md`
    margin-bottom: 50px;
  `}
`;
export const SectionActionTitle = styled.h1`
  max-width: 642px;
  font-family: "CircularMedium";
  font-size: 3.8rem;
  font-weight: 500;
  text-align: center;
  color: ${colors.black};

  & > strong {
    color: ${colors.blue3};
  }

  ${mixinRespondTo.md`
    font-size: 3rem;
  `}
`;
export const SectionActionDesc = styled.div`
  ${mixinWrapper};
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  & > p {
    opacity: 0.9;
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    text-align: left;
    line-height: 1.5;
    color: ${colors.gray3};
    width: 100%;
    max-width: 531px;

    &:last-child {
      margin-left: 38px;
    }
  }

  ${mixinRespondTo.md`
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
   
    & > p{
      margin: 16px 0;

      &:last-child{
        margin-left: 0;
      }
    }
  `}
`;

export const SectionChallenge = styled.div`
  padding: 71px 0 108px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const SectionChallengeTitle = styled.h1`
  font-family: "CircularBlack";
  font-size: 7.2rem;
  font-weight: 900;
  letter-spacing: 0.72px;
  text-align: center;
  color: ${colors.black};
  max-width: 258px;
  margin-bottom: 23px;
  ${mixinRespondTo.md`
    font-size: 5.2rem;
  `}
`;
export const SectionChallengeText = styled.div`
  ${mixinWrapper};
  display: flex;
  flex-direction: column;
  align-items: center;
  & > p {
    max-width: 720px;
    margin: 16px 0;
    opacity: 0.9;
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: normal;
    text-align: left;
    color: ${colors.gray3};
  }
`;
export const SectionChallengeMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 321px;
  width: 100%;
  background-image: linear-gradient(98deg, #34abaf 10%, #0052af 106%);
  margin: 32px 0;

  & > h1 {
    margin-top: 25px;
    max-width: 486px;
    font-family: "Georgia", sans-serif;
    font-size: 3.6rem;
    font-weight: normal;
    text-align: center;
    color: ${colors.white};

    ${mixinRespondTo.md`
      font-size: 3rem;
    `}
  }
`;

export const SectionChallengeQuote = styled.h1`
  position: relative;
  max-width: 689px;
  margin-top: 40px;
  margin-left: 17px;
  font-family: "Georgia", sans-serif;
  font-size: 2.2rem;
  font-weight: normal;
  font-style: italic;
  text-align: left;
  color: ${colors.black};

  ${mixinRespondTo.md`
    font-size: 1.8rem;
    width: 85%;
  `}

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -24px;
    height: 100%;
    width: 7px;
    background: ${colors.red1};
  }
`;
