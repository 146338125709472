import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ListCoursesActions } from "store/ducks/courses";
import { colors } from "@/styles/variables";
import { Separator } from "@/styles/components/Separator";
import Paginator from "@/components/Paginator";
import Loading from "@/components/Loading";
import Card from "./Card";

import * as S from "./styles";

function SectionCalendar() {
  const dispatch = useDispatch();
  const {
    data: coursesData,
    pagination,
    loading,
  } = useSelector((state) => state.listCourses);
  const [query, setQuery] = useState({
    page: 1,
    limit: 9,
    forShowingOnWebsite: 1,
  });

  const handlePageChange = useCallback(
    (page) => {
      setQuery((state) => ({ ...state, page }));
    },
    [setQuery]
  );

  const CoursesListComponent = useCallback(() => {
    if (loading) return <Loading light />;

    if (coursesData.length === 0) {
      return (
        <S.NoCursesMessage>
          Não há cursos cadastrados em andamento ou com início próximo da data
          atual
        </S.NoCursesMessage>
      );
    }

    return (
      <>
        <S.GridCards>
          {coursesData.map((c) => (
            <Card course={c} key={c.id} />
          ))}
        </S.GridCards>
        <Paginator pagination={pagination} onPageChange={handlePageChange} />
      </>
    );
  }, [coursesData, loading, handlePageChange, pagination]);

  const fetchCoursesCB = useCallback(() => {
    dispatch(ListCoursesActions.request({ ...query }));
  }, [dispatch, query]);

  useEffect(() => {
    fetchCoursesCB();
  }, [fetchCoursesCB]);

  useEffect(() => {
    return () => {
      dispatch(ListCoursesActions.reset());
    };
  }, [dispatch]);

  return (
    <S.Container>
      <S.Wrapper>
        <Separator color={colors.red1} />
        <S.Title>Calendário de Cursos</S.Title>
        <S.Description>
          Confira as próximas datas e locais dos cursos de formação:
        </S.Description>
        <CoursesListComponent />
      </S.Wrapper>
    </S.Container>
  );
}

export default SectionCalendar;
