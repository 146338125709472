import styled from "styled-components";
import { mixinWrapper, mixinRespondTo } from "@/styles/tools";
import { Button } from "@/styles/components/Button";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 43%, #f2cd38 43%);
  width: 100%;
  height: 706px;

  ${mixinRespondTo.md`
    height: 454px;
  `}
`;

export const Wrapper = styled.div`
  ${mixinWrapper};

  position: relative;
  padding-top: 80px;
  display: flex;
  ${mixinRespondTo.md`
   padding-top: 40px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `}
`;

export const CardImg = styled.img`
  width: 622px;
  height: 530px;
  object-fit: contain;

  ${mixinRespondTo.md`
    width: 318px;
    height: 270px;
  `}
`;

export const CardInfo = styled.div`
  width: 530px;
  height: 530px;
  background-color: ${colors.blue2};
  margin-top: 96px;
  margin-left: -52px;
  margin-bottom: 50px;
  padding: 69px 56px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${mixinRespondTo.md`
       margin: 0;
       padding: 30px 32px;
       width: 318px;
       height: 400px;
  `}
`;

export const CardInfoTitle = styled.h1`
  font-family: "CircularMedium";
  font-size: 3.8rem;
  font-weight: 500;
  text-align: left;
  color: ${colors.white};

  ${mixinRespondTo.md`
    font-size: 3.2rem;
  `}
`;

export const CardInfoDesc = styled.p`
  opacity: 0.9;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: left;
  line-height: 1.5;
  color: ${colors.white};
`;
export const CardInfoButton = styled(Button)``;
