import styled from "styled-components";
import { mixinWrapper, mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div``;

export const Wrapper = styled.div`
  ${mixinWrapper}
  padding-top: 72px;
`;

export const Title = styled.h1`
  font-family: "CircularMedium";
  font-size: 3.8rem;
  font-weight: 500;
  text-align: center;
  color: ${colors.black};
`;

export const GroupNewsCard = styled.div`
  display: grid;
  width: 100%;
  margin-top: 40px;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 40px;
`;

export const WrapperGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
