import styled from "styled-components";
import { colors } from "@/styles/variables";
export const Container = styled.div`
  width: 100%;
  max-width: 340px;
  height: 441px;
  background-color: ${colors.white};
  padding: 40px 33px;
  z-index: 3;
`;
export const Estado = styled.p`
  font-family: "CircularMedium", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  color: ${colors.blue5};
`;
export const Cidade = styled.h1`
  font-family: "CircularBold", sans-serif;
  font-size: 2.6rem;
  font-weight: bold;
  text-align: left;
  color: ${colors.blue2};
  margin-top: 16px;
`;
export const Title = styled.h2`
  font-family: "Roboto", sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: left;
  text-transform: lowercase;
  color: ${colors.blue3};
  margin: 22px 0 19px;

  &::first-letter {
    text-transform: uppercase;
  }
`;
export const Local = styled.p`
  opacity: 0.9;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  text-align: left;
`;

export const Date = styled.div`
  width: 100%;
  padding: 17px 26px;
  border-radius: 5px;
  background-color: ${colors.blue9};
  margin: 30px 0;
`;

export const DateStart = styled.p`
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: left;
  color: ${colors.blue10};

  & > svg {
    margin-right: 12px;
  }
`;

export const DateEnd = styled(DateStart)`
  margin-top: 17px;
`;

export const Contato = styled.p`
  opacity: 0.9;
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  text-align: left;
  color: ${colors.gray3};
`;
