export const breakpoints = {
  xxs: "320px",
  xs: "480px",
  sm: "768px",
  md: "992px",
  lg: "1200px",
};

export const colors = {
  white: "#fff",
  black: "#000",
  gray1: "#ebebeb",
  gray2: "#f2f2f2",
  gray3: "#4d4d4d",
  gray4: "#bcbcbc",
  gray5: "#e6e6e6",
  gray6: "#c1c1c1",
  gray7: "#c8c8c8",
  gray8: "#d6d6d6",
  gray9: "#b7bbbc",
  gray10: "#aeaeae",
  gray11: "#363636",
  gray12: " #707070",
  gray13: " #e4e8ee",
  blue1: "#00224e",
  blue2: "#002b63",
  blue3: "#34abaf",
  blue4: "#42d6db",
  blue5: "#2e3e53",
  blue6: "#d4dfee",
  blue7: "#99a7ba",
  blue8: "#13243b",
  blue9: "#e0e7ef",
  blue10: "#0e1927",
  blue11: "#101e30",
  blue12: "#63758f",
  yellow1: "#f2cd38",
  yellow2: "#ffee00",
  yellow3: "#ffd800",
  yellow4: "#ffd500",
  red1: "#e54141",
};
