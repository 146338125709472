import styled from "styled-components";
import { mixinRespondTo } from "@/styles/tools";

export const Container = styled.div`
  margin-left: 40px;
  max-width: 339px;

  ${mixinRespondTo.md`
  order: 1;
     margin-left: 0;
  `}
`;

export const DocsGroup = styled.div`
  margin-top: 40px;
`;

export const Title = styled.h1`
  font-family: "CircularMedium", sans-serif;
  font-size: 3rem;
  font-weight: 500;
  text-align: left;
`;
