import { call, put } from "redux-saga/effects";
import { api } from "services";
import { RequestErrorHandler } from "utils";

import { CepActions } from "store/ducks/cep";

export function* cepRequest(action) {
  try {
    const { cep } = action;

    const { data } = yield call(api.get, `cep/${cep}`);

    yield put(CepActions.success(data));
  } catch (error) {
    const { errorMessage } = RequestErrorHandler(error);
    yield put(CepActions.failure(errorMessage));
  }
}
