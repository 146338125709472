import styled from "styled-components";
import { Separator as SeparatorItem } from "@/styles/components/Separator";
import { mixinWrapper, mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Mystery = styled.section`
  ${mixinWrapper}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 720px;
  width: 100%;
  padding-top: 64px;

  & > h1 {
    font-family: "CircularBlack", sans-serif;
    font-size: 3.8rem;
    font-weight: 900;
    letter-spacing: 0.38px;
    text-align: center;
    color: ${colors.black};
  }

  & > p {
    font-family: "Roboto", sans-serif;
    font-size: 1.6rem;
    font-weight: normal;
    letter-spacing: normal;
    text-align: center;
    line-height: 1.5;
    color: ${colors.gray3};
    margin: 12px 0;
  }
`;

export const Prayer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 52px 0;
  margin-top: 50px;
  width: 100%;
  background-image: linear-gradient(98deg, #34abaf 10%, #0052af 106%);

  & > svg {
    color: ${colors.yellow2};
  }

  & > p {
    font-family: "CircularBlack", sans-serif;
    font-size: 1.6rem;
    font-weight: 900;
    letter-spacing: 0.16px;
    text-align: center;
    color: ${colors.blue11};
    margin: 24px 0;
  }

  & > h1 {
    font-family: "Georgia", sans-serif;
    font-size: 3.6rem;
    font-weight: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${colors.white};
    max-width: 486px;
  }

  ${mixinRespondTo.md`
   & > h1 {
     font-size: 3.1rem;
   }
  `}
`;

export const Separator = styled(SeparatorItem)`
  max-width: 50px;
  margin: 40px 0;
`;
