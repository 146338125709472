import React, { useCallback } from "react";
import { useGoTop } from "@/hooks/useGoTop";
import Swiper from "react-id-swiper";
import Banner from "@/components/Banner";
import Jumbotron from "@/components/Jumbotron";
import { Button } from "@/styles/components/Button";
import Banner12Passos from "@/assets/images/jumbotron/Jumbotron3.jpg";
import Jumbotron1Img from "@/assets/images/jumbotron/Jumbotron1.jpg";
import { ReactComponent as QuotesIcon } from "@/assets/icons/quotes.svg";
import { CalendarDownloader } from "@/components/CalendarDownloader";

import StepCard from "./StepCard";

import {
  Container,
  StepGroup,
  StepCarousel,
  Wrapper,
  OracaoWrapper,
  Title,
  Description,
  Message,
  Slide,
} from "./styles";

function Steps() {
  const steps = [
    {
      title: "Admitir",
      text: "Senhor, ADMITO minha dependência dos vícios e pecados, e que sozinho, não posso vencê-los. Liberta-me!",
    },
    {
      title: "Confiar",
      text: "Senhor, CONFIO em Ti, ouve o meu clamor. Cura-me!",
    },
    {
      title: "Entregar",
      text: "Senhor, ENTREGO minha vida, minhas dependências, em tuas mãos. Espero em Ti. Aceita-me!",
    },
    {
      title: "Arrepender-se",
      text: "Senhor, ARREPENDIDO de tudo que fiz, quero voltar para a tua graça, para a casa do Pai. Acolhe-me!",
    },
    {
      title: "Confessar",
      text: "Senhor, CONFESSO meus pecados, e publicamente, peço teu perdão e o perdão dos meus irmãos. Absolve-me!",
    },
    {
      title: "Renascer",
      text: "Senhor, RENASÇO no teu Espírito para a Sobriedade. O homem velho passou, eis que sou uma criatura nova. Batiza-me!",
    },
    {
      title: "Reparar",
      text: "Senhor, REPARO financeira e moralmente a todos que, na minha dependência, eu prejudiquei. Ajuda-me a resgatar minha dignidade e a confiança dos meus. Restaura-me!",
    },
    {
      title: "Professar a Fé",
      text: "Senhor, PROFESSO que creio na Santíssima Trindade e peço a ajuda da Igreja, com a interceção de todos os santos. Instrui-me na Tua Palavra!",
    },
    {
      title: "Orar e Vigiar",
      text: "Senhor, ORANDO e VIGIANDO para não cair em tentação, seremos perseverantes nos Teus ensinamentos. Dá-me a Tua Paz!",
    },
    {
      title: "Servir",
      text: "Senhor, SERVINDO, a exemplo de Maria, nossa mãe e de todos, queremos, gratuitamente, fazer dos excluídos os nossos preferidos, através da Pastoral da Sobriedade.",
    },
    {
      title: "Celebrar",
      text: "Senhor, CELEBRANDO a Eucaristia, em comunidade com os irmãos, teremos força e graça, para perseverarmos nesta caminhada. Alimenta-nos no Corpo e Sangue de Jesus!",
    },
    {
      title: "Festejar",
      text: "Senhor, FESTEJANDO os 12 passos para a Sobriedade Cristã, irmanados com todos, na mesma esperança, por uma vida sem drogas, queremos partilhar e anunciar Jesus Cristo Redentor, pelo nosso testemunho.",
    },
  ];
  const params = {
    slidesPerView: 1,
    spaceBetween: 20,
    shouldSwiperUpdate: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  };
  useGoTop();

  const Oracao12Passos = useCallback(() => {
    return (
      <OracaoWrapper>
        <Title>Oração dos 12 Passos</Title>
        <Description>
          Leia verdadeiramente as afirmações de cada um dos passos e depois
          repita 3 vezes:
        </Description>
        <Description>
          "Piedade Redentora de Cristo, dai-nos a Sobriedade."
          <br />
          "Sobriedade e Paz, só por hoje, graças a Deus."
        </Description>
      </OracaoWrapper>
    );
  }, []);

  const OracaoSobriedade = useCallback(() => {
    return (
      <OracaoWrapper>
        <Title>Oração da Sobriedade</Title>
        <Description>
          "Senhor, admito minha dependência.
          <br />
          Sei que minha libertação não será imediata.
        </Description>
        <Description>
          Dependerá da minha perserverança na vivencia do Programa de Vida Nova.
          <br />
          Confio em ti.
        </Description>
        <Description>
          Entrego minha vida
          <br />
          em tuas mãos.
        </Description>
        <Description>
          Arrependido, confesso meus pecados
          <br />
          para renascer no teu esírito sendo uma criatura nova.
        </Description>
        <Description>
          Quero reparar os males que causei,
          <br />
          Professar a fé, orar e vigiar para não cair em tentação.
        </Description>
        <Description>
          Servir gratuitamente para fazer dos exluídos os preferidos.
        </Description>
        <Description>
          Celebrar a eucaristia com os irmãos por uma vida plena,
          <br />
          sem males e sem drogas.
        </Description>
        <Description>
          Festejar cada dia de sobriedade,
          <br />
          só por hoje, graças a Deus.
        </Description>
        <Description>Amém"</Description>
        <Description>
          * Soriedade e paz!
          <br />
          Só por hoje, graças a Deus! (3x)
        </Description>
      </OracaoWrapper>
    );
  }, []);

  const OracaoVidaNova = useCallback(() => {
    return (
      <OracaoWrapper>
        <Title>Oração do Programa de Vida Nova</Title>
        <Description>
          Senhor, admito minha dependência dos vícios e pecados, e que sozinho,
          <br />
          Não posso vencê-los. Liberta-me!
        </Description>
        <Description>
          Senhor, confio em ti, ouve o meu clamor. Cura-me!
        </Description>
        <Description>
          Senhor, entrego minha vida, minhas dependências em tuas mãos.
          <br />
          Espero em ti. Aceita-me!
        </Description>
        <Description>
          Senhor, arrependido de tudo que fiz, quero voltar para tua graça,
          <br />
          Para a casa do pai. Acolhe-me!
        </Description>
        <Description>
          Senhor, confesso meus pecados, e publicamente,
          <br />
          Peço teu perdão e o perdão dos meus irmãos.
          <br />
          Absolve-me!
        </Description>
        <Description>
          Senhor, renasço no teu espírito, para a sobriedade. O homem velho
          <br />
          Passou, eis que sou uma criatura nova.
          <br />
          Batiza-me!
        </Description>
        <Description>
          Senhor, reparo financeira e moralmente a todos que na minha
          <br />
          Dependência eu prejudiquei. Ajuda-me a resgatar a minha dignidade
          <br />
          E a confiança dos meus.
          <br />
          Restaura-me!
        </Description>
        <Description>
          Senhor, professo que creio na santíssima trindade e peço ajuda da
          <br />
          Igreja com a intercessão de todos os santos. Instrui-me na tua
          palavra!
        </Description>
        <Description>
          Senhor, orando e vigiando para não cair em tentação
          <br />
          Seremos perseverantes nos teus ensinamentos.
          <br />
          Dá-me a tua paz!
        </Description>
        <Description>
          Senhor, servindo a exemplo de maria, nossa mãe e mãe de todos,
          <br />
          Queremos gratuitamente, fazer dos excluídos os nossos preferidos,
          <br />
          Através da pastoral da sobriedade.
        </Description>
        <Description>
          Senhor, celebrando a eucaristia em comunidade com os irmãos, teremos
          <br />
          Força e graça para perseverarmos nessa caminhada.
          <br />
          Alimenta-nos no corpo e sangue de Jesus!
        </Description>
        <Description>
          Senhor, festejando os doze passos para a sobriedade cristã, irmanados
          <br />
          Com todos na mesma esperança por uma vida sem drogas,
          <br />
          Queremos partilhar e anunciar jesus cristo redentor
          <br />
          Pelo nosso testemunho.
          <br />
          Amém!
        </Description>
        <Description>
          *piedade redentora de Cristo,
          <br />
          Dai-nos a sobriedade (3x)
        </Description>
      </OracaoWrapper>
    );
  }, []);

  return (
    <Container>
      <Banner
        image={Banner12Passos}
        title="Os 12 Passos"
        description="Os 12 Passos da Pastoral da Sobriedade são vivenciados periódica e ciclicamente traduzindo um
Programa de Vida Nova que cumpre a primeira missão da Igreja: a evangelização."
        descriptionWidth={732}
      />

      <Wrapper>
        <Title>Os 12 Passos são:</Title>
        <StepGroup>
          {steps.map((step, index) => (
            <StepCard
              key={index}
              item={index + 1}
              title={step.title}
              text={step.text}
            />
          ))}
        </StepGroup>
        <StepCarousel>
          <Swiper {...params}>
            {steps.map((step, index) => (
              <Slide key={index}>
                <StepCard
                  item={index + 1}
                  title={step.title}
                  text={step.text}
                />
              </Slide>
            ))}
          </Swiper>
        </StepCarousel>
      </Wrapper>

      <Oracao12Passos />
      <OracaoSobriedade />
      <OracaoVidaNova />

      <CalendarDownloader />

      <Message>
        <QuotesIcon />
        <h1>Sobriedade e Paz, só por hoje, graças a Deus!</h1>
      </Message>

      <Jumbotron image={Jumbotron1Img}>
        <h1>Encontre um grupo de autoajuda perto de você</h1>
        <p>
          Nossos grupos estão por todo o Brasil, nas comunidades e paróquias da
          Igreja. Realizamos reuniões semanais onde se propõe um Programa de
          Vida Nova, através dos 12 passos, baseados na reflexão e vivência de
          12 temas bíblicos de conversão!
        </p>
        <div>
          <Button to="/grupos/encontrar-grupo">Encontre um grupo</Button>
        </div>
      </Jumbotron>
    </Container>
  );
}

export default Steps;
