import { call, put } from "redux-saga/effects";
import { api, notify } from "services";
import { RequestErrorHandler } from "utils";

import { EventActions } from "store/ducks/events";

export function* fetchEventRequest(action) {
  try {
    const { id } = action;
    const { data } = yield call(api.get, `open/event/${id}`);
    yield put(EventActions.success(data));
  } catch (error) {
    const { errorMessage } = RequestErrorHandler(error);
    notify("error", errorMessage);
    yield put(EventActions.failure(errorMessage));
  }
}
