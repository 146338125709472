import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import GA4React from "ga-4-react";

import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Home from "@/pages/Home";

import About from "@/pages/About/About";
import Course from "@/pages/About/Course";
import Archives from "@/pages/About/Archives";
import Third from "@/pages/About/Third";
import Statistics from "@/pages/About/Statistics";
import StatisticsOld from "@/pages/About/Statistics.old";

import Steps from "@/pages/Steps";

import Works from "@/pages/Group/Works";
import Implant from "@/pages/Group/Implant";
import Group from "@/pages/Group/Group";

import News from "@/pages/News/News";
import NewsDetails from "@/pages/News/NewsDetails";
import Events from "@/pages/News/Events";

import Communities from "@/pages/Community/Communities";
import Register from "@/pages/Community/Register";
import RegistrationCompleted from "@/pages/Community/RegistrationCompleted";

const ga4react = new GA4React("G-C1RNM3LPMZ");

export default function Routes() {
  const location = useLocation();

  ga4react.initialize().then(
    (ga4) => {
      ga4.pageview(`${location.pathname}`);
      ga4.gtag("event", "pageview", `${location.pathname}`);
    },
    (err) => {
      console.error(err);
    }
  );

  return (
    <>
      <Header />
      {/* <TransitionGroup>
        <CSSTransition key={location.pathname} timeout={300} classNames="fade"> */}
      <Switch location={location}>
        <Route path="/" exact component={Home} />
        <Route
          path="/sobre"
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`} exact component={About} />
              <Route path={`${url}/curso-formacao`} component={Course} />
              <Route path={`${url}/terco-sobriedade`} component={Third} />
              <Route path={`${url}/arquivos-materiais`} component={Archives} />
              <Route
                path={`${url}/estatisticas/old`}
                component={StatisticsOld}
              />
              <Route path={`${url}/estatisticas`} component={Statistics} />
            </>
          )}
        />

        <Route path="/12-passos" component={Steps} />

        <Route
          path="/grupos"
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/como-funciona`} component={Works} />
              <Route path={`${url}/encontrar-grupo`} component={Group} />
              <Route path={`${url}/como-implantar`} component={Implant} />
            </>
          )}
        />

        <Route
          path="/novidades"
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/noticias`} exact component={News} />
              <Route path={`${url}/noticias/:id`} component={NewsDetails} />
              <Route path={`${url}/eventos`} component={Events} />
            </>
          )}
        />

        <Route
          path="/comunidades"
          render={({ match: { url } }) => (
            <>
              <Route
                path={`${url}/nossas-comunidades`}
                component={Communities}
              />
              <Route path={`${url}/cadastro`} exact component={Register} />
              <Route
                path={`${url}/cadastro/completo`}
                component={RegistrationCompleted}
              />
            </>
          )}
        />

        <Route path="*" exact={true} component={Home} />
      </Switch>
      {/* </CSSTransition>
      </TransitionGroup> */}
      <Footer />
    </>
  );
}
