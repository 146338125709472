import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "@unform/web";

import { StatisticsActions } from "store/ducks/statistics";
import { SelectCitiesActions } from "store/ducks/cities";
import { useSetFieldValues } from "hooks";
import { statesOptions } from "utils";

import { useGoTop } from "@/hooks/useGoTop";
import Banner from "@/components/Banner";
import Select from "@/components/Form/Select";
import Loading from "@/components/Loading";

import BannerStatistics from "@/assets/images/banner/BannerStatistics.jpg";

import * as S from "./styles";

function StatisticsOld() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    city_id: null,
    uf: null,
  });
  const [local, setLocal] = useState({
    city: null,
    state: null,
  });

  const { setValuesForSelectInputs } = useSetFieldValues(formRef, query);
  const { data: statisticsData, loading } = useSelector(
    (state) => state.statistics
  );
  const { data: citiesOptions } = useSelector((state) => state.selectCities);

  function renderMainTitle() {
    const { state, city } = local;

    let title;
    switch (true) {
      case city !== null:
        title = `Estatísticas para ${city}, ${state}`;
        break;
      case state !== null:
        title = `Estatísticas para ${state}`;
        break;
      default:
        title = "Estatísticas nacionais";
    }

    return <S.Title>{title}</S.Title>;
  }

  function renderGroupsCard() {
    const { ongoingGroups, finishedGroups, allGroups } = statisticsData;
    return (
      <S.Card>
        <S.CardTitle>Grupos de Autoajuda da Pastoral da Sobriedade</S.CardTitle>
        <S.CardRow>
          <S.CardLabel>Grupos em andamento</S.CardLabel>
          <S.CardValue>{ongoingGroups}</S.CardValue>
        </S.CardRow>
        <S.CardRow>
          <S.CardLabel>Grupos finalizados</S.CardLabel>
          <S.CardValue>{finishedGroups}</S.CardValue>
        </S.CardRow>
        <S.CardRow>
          <S.CardLabel>Todos os grupos</S.CardLabel>
          <S.CardValue>{allGroups}</S.CardValue>
        </S.CardRow>
      </S.Card>
    );
  }

  function renderCoursesCard() {
    const { ongoingCourses, finishedCourses, allCourses } = statisticsData;
    return (
      <S.Card>
        <S.CardTitle>Cursos oferecidos pela Pastoral da Sobriedade</S.CardTitle>
        <S.CardRow>
          <S.CardLabel>Cursos em andamento</S.CardLabel>
          <S.CardValue>{ongoingCourses}</S.CardValue>
        </S.CardRow>
        <S.CardRow>
          <S.CardLabel>Cursos finalizados</S.CardLabel>
          <S.CardValue>{finishedCourses}</S.CardValue>
        </S.CardRow>
        <S.CardRow>
          <S.CardLabel>Todos os Cursos</S.CardLabel>
          <S.CardValue>{allCourses}</S.CardValue>
        </S.CardRow>
      </S.Card>
    );
  }

  function renderCommunitiesCard() {
    const { registeredCommunities, freeVacancies, totalVacancies } =
      statisticsData;
    return (
      <S.Card className={"dark"}>
        <S.CardTitle>Comunidades Terapêuticas Parceiras</S.CardTitle>
        <S.CardRow>
          <S.CardLabel>Comunidades registradas</S.CardLabel>
          <S.CardValue>{registeredCommunities}</S.CardValue>
        </S.CardRow>
        <S.CardRow>
          <S.CardLabel>Vagas gratuitas</S.CardLabel>
          <S.CardValue>{freeVacancies}</S.CardValue>
        </S.CardRow>
        <S.CardRow>
          <S.CardLabel>Total de vagas</S.CardLabel>
          <S.CardValue>{totalVacancies}</S.CardValue>
        </S.CardRow>
      </S.Card>
    );
  }

  function renderSideContent() {
    return (
      <S.SideContent>
        <S.SideContentHeader>
          <S.Title>Outros Dados</S.Title>
        </S.SideContentHeader>
        <S.SideContentRow>
          <h4>Cursos de Capacitação</h4>
          <S.SideRowLink to="/sobre/curso-formacao">
            Confira os Cursos
          </S.SideRowLink>
        </S.SideContentRow>
        <S.SideContentRow>
          <h4>Grupos de Autoajuda</h4>
          <S.SideRowLink to="/grupos/encontrar-grupo">
            Encontre grupos
          </S.SideRowLink>
        </S.SideContentRow>
        <S.SideContentRow>
          <h4>Comunidades Terapêuticas Parceiras</h4>
          <S.SideRowLink to="/comunidades/nossas-comunidades">
            Nossas Comunidades
          </S.SideRowLink>
          <S.SideRowLink to="/comunidades/cadastro">Filie-se</S.SideRowLink>
        </S.SideContentRow>
      </S.SideContent>
    );
  }

  const handleUfChange = useCallback(
    ({ value, label }) => {
      setLocal((state) => ({
        ...state,
        state: value !== null ? label : null,
      }));
      setQuery((state) => ({ ...state, uf: value }));
    },
    [setQuery, setLocal]
  );

  const handleCityChange = useCallback(
    ({ value, label }) => {
      setLocal((state) => ({
        ...state,
        city: value !== null ? label : null,
      }));
      setQuery((state) => ({ ...state, city_id: value }));
    },
    [setQuery, setLocal]
  );

  const fetchCitiesCB = useCallback(() => {
    if (!query.uf) return null;
    dispatch(SelectCitiesActions.request({ uf: query.uf }));
  }, [dispatch, query.uf]);

  const fetchStatistics = useCallback(() => {
    dispatch(StatisticsActions.request(query));
  }, [query, dispatch]);

  useEffect(() => {
    fetchCitiesCB();
  }, [fetchCitiesCB]);

  useEffect(() => {
    fetchStatistics();
  }, [fetchStatistics]);

  useEffect(() => {
    setValuesForSelectInputs({
      uf: statesOptions,
      city_id: citiesOptions,
    });
  }, [setValuesForSelectInputs, citiesOptions]);

  useEffect(() => {
    return () => {
      dispatch(StatisticsActions.reset());
    };
  }, [dispatch]);

  useGoTop();
  return (
    <S.Container>
      <Banner
        image={BannerStatistics}
        title="Estatísticas"
        description="Dados consolidados das ações da Pastoral da Sobriedade pelo país"
        descriptionWidth={265}
      />
      <S.Wrapper>
        <S.FiltersContainer>
          <Form ref={formRef}>
            <S.FormRow>
              <Select
                name="estado"
                id="estado"
                label="Estado:"
                options={[{ label: "Todos", value: null }, ...statesOptions]}
                onChange={handleUfChange}
                placeholder="Selecione..."
              />
              <Select
                name="cidade"
                id="cidade"
                label="Cidade:"
                options={[{ label: "Todas", value: null }, ...citiesOptions]}
                isDisabled={query.uf === null}
                onChange={handleCityChange}
                placeholder="Selecione..."
              />
            </S.FormRow>
          </Form>
        </S.FiltersContainer>
        <S.StatisticsContainer>
          <S.MainContent>
            {renderMainTitle()}
            {statisticsData && (
              <>
                <S.CardsRow>
                  {renderGroupsCard()}
                  {renderCoursesCard()}
                </S.CardsRow>
                {renderCommunitiesCard()}
              </>
            )}
          </S.MainContent>
          {renderSideContent()}
        </S.StatisticsContainer>
      </S.Wrapper>
    </S.Container>
  );
}

export default StatisticsOld;
