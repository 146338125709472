import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { BsArrowLeft } from "react-icons/bs";

import { NewsActions } from "store/ducks/news";
import { date } from "utils/formatters";
import { useGoTop } from "@/hooks/useGoTop";
import Banner from "@/components/Banner";
import NewsSection from "@/components/NewsSection";
import BannerNewsImg from "@/assets/images/banner/BannerStatistics.jpg";
import { SocialButton } from "@/styles/components/Button";

import * as S from "./styles";

function NewsDetails() {
  const { data: newsData, loading } = useSelector((state) => state.news);
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  useGoTop();

  function renderTitle() {
    if (!newsData) return <></>;

    const { title, published_at } = newsData;

    return (
      <>
        <S.Title>{title}</S.Title>
        <S.Tag>
          {date(published_at, { format: "dd 'de' MMMM 'de' yyyy" })}
        </S.Tag>
      </>
    );
  }

  function renderImage() {
    if (!newsData) return <></>;
    const { imagePath } = newsData;

    return (
      <S.Picture>
        <S.Image src={imagePath} />
      </S.Picture>
    );
  }

  function renderContent() {
    if (!newsData) return <></>;
    const { post } = newsData;

    return <S.Text dangerouslySetInnerHTML={{ __html: post }}></S.Text>;
  }

  function renderSocial() {
    if (!newsData) return <></>;
    const { title } = newsData;

    return (
      <S.SocialGroup>
        <SocialButton
          color="#3a579a"
          href={`https://m.facebook.com/sharer.php?u=${encodeURIComponent(
            window.location.href
          )}`}>
          <FaFacebookF size={19} /> Compartilhar
        </SocialButton>
        <SocialButton
          color="#00acf0"
          href={`https://twitter.com/intent/tweet?url=${
            window.location.href
          }&text=${encodeURIComponent(title)}`}>
          <FaTwitter size={19} /> Twittar
        </SocialButton>
        <SocialButton
          color="#25d366"
          href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
            title
          )} --> ${window.location.href}`}>
          <FaWhatsapp size={19} /> WhatsApp
        </SocialButton>
      </S.SocialGroup>
    );
  }

  const fetchNewsCB = useCallback(() => {
    dispatch(NewsActions.request(id));
  }, [dispatch, id]);

  useEffect(() => {
    fetchNewsCB();
  }, [fetchNewsCB]);

  return (
    <S.Container>
      <Banner
        image={BannerNewsImg}
        title="Notícias"
        description="Confira as últimas novidades da Pastoral da Sobriedade."
        descriptionWidth={220}
      />
      <S.Wrapper>
        <S.WrapperCol>
          <S.Tag onClick={() => history.goBack()}>
            <BsArrowLeft size={30} />
            Voltar
          </S.Tag>
          {renderTitle()}
          {renderImage()}
        </S.WrapperCol>
        <S.WrapperCol>
          {renderContent()}
          {renderSocial()}
          <hr />
        </S.WrapperCol>
      </S.Wrapper>
      <NewsSection title="Mais notícias" query={{ excludeId: id }} />
    </S.Container>
  );
}

export default NewsDetails;
