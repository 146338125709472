export function getExcerpt(htmlString, length) {
  const span = document.createElement("span");
  span.innerHTML = htmlString;

  const outputString = span.textContent || span.innerText;

  if (outputString.length > length) {
    // obtendo os *length* primeiros caracteres sem quebrar palavras
    // usando flag s para tratar a string como single line
    const exp = new RegExp(`^(.{${length}}[^\\s]*).*`, "s");
    return outputString.replace(exp, "$1...");
  }

  return outputString;
}
