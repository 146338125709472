import React from "react";
import Doc from "./Doc";
import { Container, Title, DocsGroup } from "./styles";

function SectionFree() {
  const docs = [
    {
      title: "Como Cultivar a Espiritualidade na Pastoral da Sobriedade",
      description: "Dom Irineu Danelon",
      link: `${process.env.PUBLIC_URL}/docs/espiritualidadeDirineu.doc`,
    },
    {
      title: "Consolidando a Caminhada",
      description: "Pastoral da Sobriedade",
      link: `${process.env.PUBLIC_URL}/docs/consolidando_a_caminhada.pdf`,
    },
    {
      title: "Consolidando a Caminhada 2",
      description: "Pastoral da Sobriedade",
      link: `${process.env.PUBLIC_URL}/docs/livro-consolidando-a-caminhada-2.pdf`,
    },
  ];
  return (
    <Container>
      <Title>Materiais gratuitos</Title>
      <DocsGroup>
        {docs.map((doc) => (
          <Doc
            title={doc.title}
            description={doc.description}
            link={doc.link}
          />
        ))}
      </DocsGroup>
    </Container>
  );
}

export default SectionFree;
