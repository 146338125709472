import React from "react";
import Datasheet from "./Datasheet";
import { Container, Title, DatasheetGroup } from "./styles";

function SectionDatasheet() {
  const datasheets = [
    {
      title: "Ficha de Inscrições para cursos",
      link: `${process.env.PUBLIC_URL}/docs/Ficha_Inscricao.doc`,
    },
    {
      title: "Modelo do Relatório dos 12 Passos",
      link: `${process.env.PUBLIC_URL}/docs/modelo_rel12passos.docx`,
    },
    {
      title: "Regimento Interno",
      link: `${process.env.PUBLIC_URL}/docs/Regimento_Interno.pdf`,
    },
    {
      title: "Consolidando",
      link: `${process.env.PUBLIC_URL}/docs/Consolidando.doc`,
    },
    {
      title: "Estatuto",
      link: `${process.env.PUBLIC_URL}/docs/Estatuto.pdf`,
    },
    {
      title: "Ficha do 1º Passo",
      link: `${process.env.PUBLIC_URL}/docs/Ficha1Passo.doc`,
    },
    {
      title: "Logotipo da Pastoral",
      link: `${process.env.PUBLIC_URL}/docs/logodapastoral.zip`,
    },
    {
      title: "Cadastro das Comunidades Terapêuticas Parceiras",
      link: `${process.env.PUBLIC_URL}/docs/Ficha_Cadastro_FNCTC.doc`,
    },
    {
      title: "Estatuto FGAS 2024",
      link: `${process.env.PUBLIC_URL}/docs/ESTATUTO_FGAS_2024.pdf`,
    },
    {
      title:
        "Termo de Consentimento de uso de dados da FGAS-Pastoral da Sobriedade",
      link: `${process.env.PUBLIC_URL}/docs/termo_de_consentimento_e_termo_de_responsabilidade_agente.pdf`,
    },
    {
      title: "Pastoral da Sobriedade Site",
      link: `${process.env.PUBLIC_URL}/docs/Pastoral_da_Sobriedade_site.pdf`,
    },
  ];
  return (
    <Container>
      <Title>Fichas e outros documentos</Title>

      <DatasheetGroup>
        {datasheets.map((datasheet, index) => (
          <Datasheet
            key={index}
            title={datasheet.title}
            link={datasheet.link}
          />
        ))}
      </DatasheetGroup>
    </Container>
  );
}

export default SectionDatasheet;
