import styled from "styled-components";
import { mixinWrapper, mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div``;

export const StepGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 44px;
  grid-row-gap: 32px;
  margin: 48px 0 89px;

  ${mixinRespondTo.md`
    display: none;
  `}
`;

export const StepCarousel = styled.div`
  display: none;
  max-width: 340px;
  justify-content: center;
  align-items: center;
  margin: 30px auto;

  ${mixinRespondTo.md`
    display: flex;
  `}
`;

export const Wrapper = styled.div`
  ${mixinWrapper}
  padding: 64px 0 72px;
`;

export const OracaoWrapper = styled(Wrapper)``;

export const Title = styled.h1`
  font-family: "CircularBlack", sans-serif;
  font-size: 3.8rem;
  font-weight: 900;
  letter-spacing: 0.38px;
  text-align: center;
  color: ${colors.black};
`;

export const Description = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  text-align: center;
  color: ${colors.gray3};
  padding: 12px 0;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 321px;
  width: 100%;
  background-image: linear-gradient(98deg, #34abaf 10%, #0052af 106%);

  & > h1 {
    margin-top: 25px;
    max-width: 486px;
    font-family: "Georgia", sans-serif;
    font-size: 3.6rem;
    font-weight: normal;
    text-align: center;
    color: ${colors.white};

    ${mixinRespondTo.md`
      font-size: 3.2rem;
    `}
  }
`;

export const Slide = styled.div`
  margin-bottom: 20px;
`;
