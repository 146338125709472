import styled from "styled-components";
import { Link } from "react-router-dom";
import { Form as FormUnform } from "@unform/web";

import { mixinWrapper } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div``;

export const Wrapper = styled.div`
  ${mixinWrapper};
  padding: 52px 0;
`;

export const Title = styled.h2`
  font-family: "CircularMedium";
  font-size: 26px;
  margin-bottom: 42px;
`;

// Main area

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 64px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const MainContent = styled.div`
  flex: 1 1 100%;
`;

// Result

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResultHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.gray1};
  padding-bottom: 24px;
  margin-bottom: 32px;
`;

export const ResultTitle = styled.h3``;

// header form
export const Form = styled(FormUnform)`
  width: 200px;
  div.field-container {
    margin: unset;
  }
`;

// Sidebar

export const SideContent = styled.div`
  @media screen and (min-width: 1001px) {
    width: 330px;
    margin-left: 60px;
  }
  @media screen and (max-width: 1000px) {
    margin-top: 64px;
  }
`;

export const SideContentHeader = styled.div`
  border-bottom: 4px solid ${colors.blue5};
`;

export const SideContentRow = styled.div`
  padding: 30px 0px;
  border-bottom: 1px solid #b2b2b2;

  h4 {
    color: #000;
    font-family: "Roboto", sans-serif;
    margin-bottom: 12px;
    font-size: 17px;
  }
`;

export const SideRowLink = styled(Link)`
  color: ${colors.blue3};
  font-family: "RobotoBold", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;
