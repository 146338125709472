import React, { useCallback, useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { toast } from "react-toastify";
import { BrowserRouter, useHistory } from "react-router-dom";

import "./config/ReactotronConfig";

import "react-toastify/dist/ReactToastify.css";
import "swiper/css/swiper.min.css";
import "react-vis/dist/style.css";

import { store, persistor } from "./store";
import GlobalStyles from "./styles/global";
import Routes from "./routes";

toast.configure({
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
});
function App() {
  const history = useHistory();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter history={history} basename="/">
          <Routes />
          <GlobalStyles />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
