import styled, { keyframes, css } from "styled-components";
import { colors } from "@/styles/variables";

export const spin = keyframes`
   to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Spinner = styled.div`
  width: 50px;
  height: 50px;
  margin: 50px;
  border-radius: 50%;
  border: 0.3rem solid rgba(0, 0, 0, 0.3);
  border-top-color: ${colors.black};
  animation: 1.5s ${spin} infinite linear;

  ${(props) =>
    props.light &&
    css`
      border: 0.3rem solid rgba(255, 255, 255, 0.3);
      border-top-color: ${colors.white};
    `}
`;
