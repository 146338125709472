import styled from "styled-components";
import { mixinWrapper, mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 558px;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${colors.black};
    opacity: 0.5;
  }

  ${mixinRespondTo.md`
    height: 420px;
  `}
`;

export const BannerWrapper = styled.div`
  ${mixinWrapper};
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${mixinRespondTo.md`
     
      margin-top: 20%;
    `}
`;

export const BannerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  & > h1 {
    font-family: "CircularBold";
    font-size: 7rem;
    font-weight: bold;
    text-align: center;
    color: ${colors.white};
    width: 100%;
    max-width: ${(props) =>
      props.titleWidth ? `${props.titleWidth}px` : "100%"};

    ${mixinRespondTo.md`
      font-size: 5rem;
    `}
  }

  & > p {
    max-width: ${(props) =>
      props.descriptionWidth ? `${props.descriptionWidth}px` : "422px"};
    font-family: "Roboto", sans-serif;
    font-size: 1.7rem;
    font-weight: normal;
    text-align: center;
    color: ${colors.gray1};
    margin-top: 24px;
  }
`;
