import styled from "styled-components";
import { mixinWrapper, mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  background-color: ${colors.gray2};
`;
export const Wrapper = styled.div`
  ${mixinWrapper};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 75px 0 85px;

  ${mixinRespondTo.md`
    padding: 45px 0 55px;
  `}
`;

export const Title = styled.h1`
  font-family: "CircularMedium", sans-serif;
  font-size: 3.8rem;
  font-weight: 500;
  text-align: center;
  color: ${colors.black};
  margin-bottom: 56px;

  ${mixinRespondTo.md`
    margin-bottom: 26px;
  `}
`;

export const GroupEvents = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  grid-gap: 40px;
  justify-items: center;
`;

export const CarouselEvents = styled.div`
  display: none;
  width: 90%;
  max-width: 340px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  ${mixinRespondTo.md`
    display: flex;
  `}
`;

export const Slide = styled.div`
  margin-bottom: 20px;
`;
