import styled from "styled-components";
import { mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  margin-right: 4.2rem;
  margin-bottom: 2.4rem;
  border-radius: 4px;

  &:last-child {
    margin-right: 0;
  }

  ${mixinRespondTo.md`
   margin-right: 0;
  `};

  & > label,
  & > span {
    font-family: "Roboto", sans-serif;
    font-size: 1.7rem;
    font-weight: 500;
    color: ${(props) => (props.error ? colors.red1 : colors.black)};
    padding-bottom: 12px;
  }

  & > textarea {
    border-radius: 4px;
    border: solid 1px ${colors.gray4};
    background-color: ${colors.white};
    font-size: 1.7rem;
    padding: 17px;
    width: 100%;
  }
`;
