import { call, put } from "redux-saga/effects";
import { api } from "services";
import { RequestErrorHandler } from "utils";

import { ListCalendarsActions } from "store/ducks/calendar";

export function* listCalendarsRequest() {
  try {
    const { data } = yield call(api.get, "calendar");
    yield put(ListCalendarsActions.success(data));
  } catch (error) {
    const { errorMessage } = RequestErrorHandler(error);
    yield put(ListCalendarsActions.failure(errorMessage));
  }
}
