import axios from "axios";
import querystring from "querystring";

const fixedIp = "192.167.0.131";

const environments = {
  local: {
    api: "http://localhost:3333",
  },
  fixedIp: {
    api: `http://${fixedIp}:3333`,
  },
  production: {
    api: "https://sobriedade-backend.herokuapp.com/",
  },
};

const baseUrl =
  process.env.NODE_ENV === "development"
    ? environments.local
    : environments.production;

export const api = axios.create({
  baseURL: baseUrl.api,
});

export const ibge = axios.create({
  baseURL: "https://servicodados.ibge.gov.br/api/v1/",
});

export const queryBuilder = (params) => {
  const cleanParams = {};
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) cleanParams[key] = value;
  });
  return querystring.stringify(cleanParams);
};
