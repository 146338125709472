import { call, put } from "redux-saga/effects";
import { api, queryBuilder } from "services";
import { RequestErrorHandler } from "utils";

import { ListEventsActions } from "store/ducks/events";

export function* listEventsRequest(action) {
  try {
    const { query = {} } = action;
    const queryString = queryBuilder({ ...query, unblockedOnly: 1 });

    const { data: responseBody } = yield call(
      api.get,
      `open/event?${queryString}`
    );

    const { data, pagination } = responseBody;
    yield put(ListEventsActions.success(data, pagination));
  } catch (error) {
    const { errorMessage } = RequestErrorHandler(error);
    yield put(ListEventsActions.failure(errorMessage));
  }
}
