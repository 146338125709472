import { css } from "styled-components";
import { breakpoints } from "./variables";

export const mixinRespondTo = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (max-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {}
);

export const mixinWrapper = css`
  width: 90%;
  max-width: 1100px;
  margin: auto;

  ${mixinRespondTo.md`
   width: 85%;
  `}
`;
