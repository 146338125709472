import styled from "styled-components";
import { mixinWrapper, mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  ${mixinWrapper}
  padding: 72px 0;
`;
export const Title = styled.h1`
  font-family: "CircularMedium";
  font-size: 3.8rem;
  font-weight: 500;
  text-align: center;
  color: ${colors.black};
  max-width: 734px;
  margin: auto;
  padding-bottom: 48px;

  & > strong {
    font-weight: 500;
    color: ${colors.blue3};
  }

  ${mixinRespondTo.md`
     font-size: 3.2rem;
  `}
`;
export const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.8rem;
  font-weight: normal;
  text-align: center;
  color: ${colors.gray3};
  width: 100%;
  max-width: 720px;
  height: 486px;
  margin: auto;
`;
export const ListItem = styled.li``;
