import React from "react";

import {
  Container,
  BannerWrapper,
  BannerInfo,
  BannerList,
  BannerListItem,
  BannerButton,
} from "./styles";

function Banner({ image, itens }) {
  return (
    <Container src={image}>
      <BannerWrapper>
        <BannerInfo>
          <h1>
            A Igreja trabalhando na prevenção e recuperação da dependência
            química.
          </h1>
          <BannerButton to="/sobre">Saiba mais</BannerButton>
        </BannerInfo>

        <BannerList>
          {itens.map((item) => (
            <BannerListItem to={item.link}>
              {item.icon}
              <h1>{item.title}</h1>
              <p>{item.description}</p>
            </BannerListItem>
          ))}
        </BannerList>
      </BannerWrapper>
    </Container>
  );
}

export default Banner;
