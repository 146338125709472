import styled, { css } from "styled-components";
import { mixinRespondTo } from "@/styles/tools";
import { colors } from "@/styles/variables";

export const Container = styled.div`
  position: relative;
`;

export const MenuMobileButton = styled.div`
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  margin-top: -40px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  z-index: 3;
  background: ${colors.yellow3};
  border-radius: 50%;
  ${mixinRespondTo.md`
    display: flex;
  `}
`;

export const MenuIcon = styled.div`
  width: 20px;
  height: 3px;
  background: ${colors.black};
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(255, 101, 47, 0.2);
  transition: all 0.5s ease-in-out;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 3px;
    background: ${colors.black};
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba(255, 101, 47, 0.2);
    transition: all 0.5s ease-in-out;
  }
  &::before {
    transform: translateY(-6px);
  }
  &::after {
    transform: translateY(6px);
  }

  ${(props) =>
    props.checked &&
    css`
      transform: translateX(-50px);
      background: transparent;
      box-shadow: none;

      &::before {
        transform: rotate(45deg) translate(35px, -35px);
      }
      &::after {
        transform: rotate(-45deg) translate(35px, 35px);
      }
    `}
`;

export const MenuMobileNav = styled.div`
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vmax;
  padding-bottom: 10%;
  max-height: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: none;
  transform: translateY(-100vh);
  transition: all 0.3s ease;

  ${(props) =>
    props.checked &&
    css`
      transform: translateY(0);
      opacity: 1;
      max-height: 99999px;
      visibility: visible;
      transition: all 0.3s ease;
    `}

  & > ul {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    & > li {
      width: 100%;
      padding: 12px 0;
      color: ${colors.yellow3};
      font-size: 2rem;
      font-weight: 800;
      text-transform: uppercase;
      text-align: center;

      & > a {
        color: ${colors.yellow3};
      }
    }
  }
`;

export const SubMenuMobile = styled.ul`
  width: 100%;
  max-height: 0;
  overflow: hidden;
  flex: 1;
  opacity: 0;
  visibility: none;
  transition: all 0.3s ease;

  ${(props) =>
    props.open &&
    css`
      max-height: 9999px;
      opacity: 1;
      visibility: visible;
      transition: all 0.3s ease;
      margin-top: 14px;
    `}
  & > li {
    background: rgba(255, 216, 0, 0.7);
    padding: 14px 0;
  }
  a {
    color: ${colors.white};
  }
`;
