import React from "react";
import { FiCalendar } from "react-icons/fi";
import { date } from "utils/formatters";

import {
  Container,
  State,
  City,
  Title,
  Description,
  Date,
  Info,
} from "./styles";

function EventCard({ event }) {
  const { id, city, name, description, date_start } = event;

  function getDescription() {
    return description.length <= 140
      ? description
      : `${description.substring(0, 140)}...`;
  }

  return (
    <Container>
      <State>{city.uf}</State>
      <City>{city.name}</City>
      <Title>{name}</Title>
      {description && <Description>{getDescription()}</Description>}
      <Date>
        <FiCalendar size={18} /> {date(date_start, { format: "dd/MM/yyyy" })}
      </Date>
    </Container>
  );
}

export default EventCard;
