import React from "react";

import { Container, Title, Body } from "@/styles/components/TextColumn";

function SectionProposal() {
  return (
    <Container>
      <Title>
        Propomos a descoberta do verdadeiro sentido da <strong>vida</strong> e
        da <strong>liberdade</strong>
      </Title>

      <Body>
        <div>
          <p>
            A reunião de Autoajuda da Pastoral da Sobriedade está baseada no
            modelo sistêmico da psicologia, no qual a dificuldade de um dos
            membros da família é compartilhada por todos e, cada um, tem sua
            participação e responsabilidade no processo de mudança. O objetivo
            da reunião é tratar o problema da dependência química, abrangendo o
            grupo familiar, sem, no entanto, promover um confronto entre eles.
          </p>
          <p>
            Enquanto o dependente participa buscando a recuperação, o familiar
            participa para se orientar e ajudar de maneira efetiva na
            recuperação de seu dependente. Assim, através de uma interação
            dinâmica que é a partilha da experiência de vida de cada
            participante, que acontece nos pequenos grupos, dependente e
            co-dependente tomam consciência dos problemas que estão prejudicando
            o relacionamento familiar.
          </p>
          <p>
            A partir das reflexões dos 12 Passos, aos poucos, essa
            conscientização, deve estimular a mudança de comportamento, tornando
            dependente e familiares mais sensíveis ao sofrimento um do outro.
            Essa sensibilidade os fará mais acolhedores e compreensivos e os
            levará a demonstrar a caridade e o amor incondicional, que todo
            cristão tem o dever de vivenciar, principalmente com as pessoas mais
            próximas, que são as que fazem parte da própria família.
          </p>
        </div>

        <div>
          <p>
            Assim sendo, a metodologia do Grupo de Autoajuda da Pastoral atende
            as exigências permanentes da Evangelização, assim como as novas
            Diretrizes Gerais da Ação Evangelizadora no Brasil focadas na
            Pessoa, na Comunidade e na Sociedade.
          </p>
          <p>
            O Grupo de Autoajuda da Pastoral da Sobriedade trabalho 12 Passos,
            baseados na reflexão e na vivência de 12 temas bíblicos que
            pretendem levar a uma conversão, a um novo programa de vida. Isto
            porque a Pastoral da Sobriedade não se limita apenas em bloquear o
            uso da droga e o abuso do álcool, mas propõe a descoberta do
            verdadeiro sentido da vida.
          </p>
          <p>
            O Grupo de Autoajuda pretende levar o co-dependente e o dependente a
            fazerem a terapia do amor, isto é, exercitarem o amor incondicional,
            pois quem ama conduz para a vida eterna, através do conhecimento de
            Jesus e de seu Projeto Libertação.
          </p>
          <p>
            Os 12 Passos contempla a doutrina da Igreja Católica e os
            Sacramentos.
            <br />
            São eles: Admitir, Confiar, Entregar, Arrepender-se, Confessar,
            Renascer, Reparar, Processar a Fé, Orar e Vigiar, Servir, Celebrar e
            Festejar.
          </p>
        </div>
      </Body>
    </Container>
  );
}

export default SectionProposal;
