import React, { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useSetFieldValues } from "hooks";
import { statesOptions } from "utils";
import { SelectCitiesActions } from "store/ducks/cities";
import { ListFnctcsActions } from "store/ducks/fnctcs";
import { useGoTop } from "@/hooks/useGoTop";
import BannerCommunityImg from "@/assets/images/banner/BannerGrupo.jpg";

import Banner from "@/components/Banner";
import Select from "@/components/Form/Select";
import Loading from "@/components/Loading";
import Paginator from "@/components/Paginator";

import CommunityCard from "./CommunityCard";

import * as S from "./styles";

function Communities() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [local, setLocal] = useState({ city: null, state: null });
  const [query, setQuery] = useState({
    limit: 6,
    registeredOnly: 1,
  });
  const [formData, setFormData] = useState({
    uf: null,
    city_id: null,
  });

  const { data: communities, pagination, loading } = useSelector(
    (state) => state.listFnctcs
  );
  const { data: citiesOptions } = useSelector((state) => state.selectCities);
  const { setValuesForSelectInputs } = useSetFieldValues(formRef, formData);

  useGoTop();

  const handleUfChange = useCallback(
    ({ value }) => {
      setLocal((state) => ({ ...state, state: value }));
      setFormData((state) => ({ ...state, uf: value }));
    },
    [setFormData]
  );

  const handleCityChange = useCallback(
    ({ value, label }) => {
      setLocal((state) => ({ ...state, city: label }));
      setFormData((state) => ({ ...state, city_id: value }));
    },
    [setFormData]
  );

  const handlePageChange = useCallback(
    (page) => {
      setQuery((state) => ({ ...state, page }));
    },
    [setQuery]
  );

  function renderCommunities() {
    if (!formData.city_id) return <></>;

    if (loading) return <Loading />;

    const { city, state } = local;
    const { total } = pagination;

    let totalMessage = "";

    switch (true) {
      case total === 0:
        totalMessage = "Não encontramos comunidades próximos a você.";
        break;
      case total === 1:
        totalMessage = "Não encontramos 1 comunidade próximo a você.";
        break;
      default:
        totalMessage = `Não encontramos ${total} comunidades próximos a você.`;
    }

    return (
      <S.Result>
        <S.Subtitle>
          Comunidades em {city}, {state}
        </S.Subtitle>
        <S.Description>{totalMessage}</S.Description>
        <S.List>
          {communities.map((c) => (
            <CommunityCard community={c} key={c.id} />
          ))}
        </S.List>
        <Paginator pagination={pagination} onPageChange={handlePageChange} />
      </S.Result>
    );
  }

  const fetchCitiesCB = useCallback(() => {
    if (!formData.uf) return null;
    dispatch(SelectCitiesActions.request({ uf: formData.uf }));
  }, [dispatch, formData.uf]);

  const fetchFnctcsCB = useCallback(() => {
    if (!formData.city_id) return null;
    dispatch(
      ListFnctcsActions.request({ ...query, city_id: formData.city_id })
    );
  }, [dispatch, formData.city_id]);

  useEffect(() => {
    fetchCitiesCB();
  }, [fetchCitiesCB]);

  useEffect(() => {
    fetchFnctcsCB();
  }, [fetchFnctcsCB]);

  useEffect(() => {
    setValuesForSelectInputs({
      uf: statesOptions,
      city_id: citiesOptions,
    });
  }, [setValuesForSelectInputs, citiesOptions]);

  useEffect(() => {
    return () => {
      dispatch(SelectCitiesActions.reset());
      dispatch(ListFnctcsActions.reset());
    };
  }, [dispatch]);

  return (
    <S.Container>
      <Banner
        image={BannerCommunityImg}
        title="Comunidades Terapêuticas Parceiras"
        titleWidth={450}
        description="Conheça as comunidades Filiadas à FGAS"
        descriptionWidth={430}
      />

      <S.Wrapper>
        <S.Title>Pesquisar Comunidade</S.Title>
        <S.Form ref={formRef}>
          <div>
            <label htmlFor="estado">Selecione um Estado:</label>
            <Select
              name="estado"
              id="estado"
              options={statesOptions}
              onChange={handleUfChange}
            />
          </div>
          <div>
            <label htmlFor="cidade">Selecione uma Cidade:</label>
            <Select
              name="cidade"
              id="cidade"
              options={citiesOptions}
              onChange={handleCityChange}
            />
          </div>
        </S.Form>
        {renderCommunities()}
      </S.Wrapper>
    </S.Container>
  );
}

export default Communities;
