import React, { useState } from "react";

import * as S from "./styles";

export default function CookiesWarning() {
  const [active, setActive] = useState(true);
  return (
    <>
      <S.Container active={active}>
        <S.Text>
          Usamos cookies pra melhorar sua experiência de navegação, personalizar
          conteúdos e anúncios. Pra mais informações, veja nossa &nbsp;
          <a target="_blank" href="./docs/politica_privacidade.pdf">
            Política de Privacidade.
          </a>
        </S.Text>
        <S.CloseWarning onClick={() => setActive(false)}>
          &times; &nbsp;Fechar
        </S.CloseWarning>
      </S.Container>
    </>
  );
}
